import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '60%',
			height: 'auto',
			margin: '40px auto',
			boxShadow: '0px 17px 27px #9D636317',
			[theme.breakpoints.down('md')]: {
				height: 'auto',
				width: '70%',
				boxShadow: 'none',
			},
			[theme.breakpoints.down('sm')]: {
				height: 'auto',
				width: '70%',
			},
		},
		div: {
			backgroundColor: '#FFFFFF',
			borderRadius: '24px',
			width: '100%',
			height: '80px',
			display: 'flex',
			flexDirection: 'row',
			textAlign: 'center',
			justifyContent: 'space-between',
			[theme.breakpoints.down('md')]: {
				height: '60px',
				backgroundColor: theme.palette.background.default,
			},
			[theme.breakpoints.down('sm')]: {
				height: '130px',
				flexDirection: 'column',
			},
		},
		divInput: {
			margin: 'auto',
			height: '54px',
			borderRadius: '14px',
			border: 'none',
			width: '70%',
			position: 'relative',
			overflow: 'hidden',
			[theme.breakpoints.down('md')]: {
				height: '41px',
				borderRadius: '9px',
				boxShadow: '0px 17px 27px #9D636317',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				height: '41px',
				marginTop: '10px',
			},
		},
		input: {
			fontFamily: 'Montserrat',
			borderRadius: '14px',
			width: '100%',
			height: '100%',
			color: '#080808',
			paddingLeft: '10px',
			fontSize: '24px',
			outline: 'none',
			background: '#FFFFFF',
			border: 'none',
			'&:focus + label span, &:valid + label span': {
				display: 'none',
			},
			[theme.breakpoints.down('lg')]: {
				fontSize: '20px',
			},
			[theme.breakpoints.down('md')]: {
				borderRadius: '9px',
				fontSize: '14px',
				'&:focus + label span, &:valid + label span': {
					display: 'none',
				},
			},
		},
		label: {
			fontSize: '24px',
			position: 'absolute',
			bottom: '3px',
			left: '10px',
			width: '100%',
			height: '100%',
			pointerEvents: 'none',
			color: '#D5CFCF',
			[theme.breakpoints.down('lg')]: {
				fontSize: '20px',
				bottom: '6px',
			},
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
				bottom: '6px',
			},
			[theme.breakpoints.down('sm')]: {
				bottom: '6px',
			},
		},
		name: {
			position: 'absolute',
			bottom: '5px',
			left: '0px',
			transition: 'all 0.3s ease',
			color: '#989898',
		},

		button: {
			fontFamily: 'Montserrat',
			background: '#E61737',
			borderRadius: '14px',
			width: '25%',
			textTransform: 'none',
			margin: 'auto',
			height: '54px',
			border: 'none',
			fontSize: '18px',
			color: 'white',
			cursor: 'pointer',
			[theme.breakpoints.down('md')]: {
				height: '41px',
				fontSize: '13px',
				borderRadius: '9px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '50%',
				margin: '10px auto',
			},
		},
	})
);

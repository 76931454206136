import React from 'react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';
import Login from '../components/Login/Login';
import WelcomeText from '../components/WelcomeText/WelcomeText';
import FullwidthCarousel from '../components/FullWidthCarousel';

interface Props {
	component: React.ComponentType;
}

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		div: {
			height: 80,
			[theme.breakpoints.down('sm')]: {
				height: 74,
			},
		},
	})
);

const ProtectedRoute = ({ component: RouteComponent }: Props) => {
	const classes = useStyles();
	const { user, isLogged } = useSelector((state: RootState) => state.session);
	const [openLogin, setOpenLogin] = useState<boolean>(true);
	const [openRegister, setOpenRegister] = useState<boolean>(false);

	const userLogged = user && isLogged;

	// return <RouteComponent />;

	if (userLogged) {
		return <RouteComponent />;
	} else {
		//return <Navigate to="/" />;
		return (<>
			<div className={classes.div} />
			<FullwidthCarousel />
			<WelcomeText />
			<Login
				openLogin={openLogin}
				setOpenLogin={setOpenLogin}
				openRegister={openRegister}
				setOpenRegister={setOpenRegister}
			/>
		</>)
	}
};

export default ProtectedRoute;

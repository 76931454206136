import { Dialog, DialogContent, Link, Box } from '@mui/material';
import { CustomButton } from './UseStyle';

interface Props {
	open: boolean;
	setOpen: (arg: boolean) => void;
}

const NoCredential = ({ open, setOpen }: Props) => (
	<Dialog
		PaperProps={{
			style: { borderRadius: 20 },
		}}
		fullWidth
		maxWidth={'sm'}
		open={open}
		onClose={() => setOpen(false)}
	>
		<DialogContent>
			<Box textAlign={'center'}>
				<h2>Revisa tus credenciales</h2>
				<p style={{ color: '#414141', maxWidth: 400, margin: 'auto' }}>
					Te recordamos que para ingresar es necesario que tengas tu registro en
					Mi Cuenta Infonavit.
				</p>

				<Link
					target="_blank"
					href="https://micuenta.infonavit.org.mx/"
					style={{ margin: '20px 0px', display: 'block' }}
				>
					Ir a Mi Cuenta Infonavit
				</Link>

				<CustomButton
					type="button"
					variant="contained"
					onClick={() => setOpen(false)}
				>
					Reintentar
				</CustomButton>
			</Box>
		</DialogContent>
	</Dialog>
);

export default NoCredential;

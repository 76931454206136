import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useStyles } from './styles';
import news2 from '../../assets/img/newsletter/exitosamente2.png';
import close from '../../assets/img/buttom/close.svg';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import { newsletterAPI } from '../../api/modules/newsletter';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const NewsletterConfirmNo = () => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [closeModal, setCloseModal] = useState<boolean>(true);
	const { user } = useSelector((state: RootState) => state.session);
	const token: string | undefined = user?.token;
	const id: number | undefined = user?.id;
	const closeFunction = () => {
		setCloseModal(!closeModal);
		answer();
		//dispatch(actions.setNotOpenNewsletter());
	};
	const answer = async () => {
		await newsletterAPI.updateMemberNewsletterSuscription(
			id,
			true,
			token
		).then(function(data) {
			const userNew = data;
			//console.log(userNew.data)
			
			dispatch(actions.setUser({
				 		...userNew.data,
						email: user?.email,
						role: user?.role,
						token: user?.token,
						sign_in_count: user?.sign_in_count,
				 	}))
			//dispatch(actions.setIsOpenNewsletter());
		});
	};

	return (
		<>
			{closeModal && (
				<Box className={classes.background}>
					<Box className={`${classes.container} ${classes.containerConfirmYes}`}>
						<div className={classes.containerData}>
							<div className={classes.divText}>
								<h1 className={`${classes.title} ${classes.titleYes}`}>
									
								</h1>
								<img src={news2} className={classes.imageYes} />
								<p className={`${classes.text} ${classes.textYes}`}>
									¡Te has registrado exitosamente!
								</p>
							</div>

							<button
								onClick={closeFunction}
								className={`${classes.button} ${classes.buttonYes}`}
								style={{ borderRadius: '14px' }}
							>
								<span className={classes.span}>Aceptar</span>
							</button>
						</div>
					</Box>
				</Box>
			)}
            
		</>
	);
};

export default NewsletterConfirmNo;

import styled from "@emotion/styled";
import { Link } from "react-router-dom";

export const Title = styled.h4`
  font-size: 12px;
  font-weight: 500;
  color: black;
  text-align: center;
`;

export const Icon = styled.img`
  diplay: inline-flex;
  width: 42px;
  height: 42px;
  object-fit: contain;
  object-position: center;
  filter: saturate(0) brightness(0);
  margin: auto auto 0 auto;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

export const CategoryLink = styled.a`
  flex: 1 0 0;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  min-width: 100px;
  padding: 10px;

  &:hover {
    img {
      filter: initial;
    }

    h4 {
      color: #DD0528;
    }
  }

  @media (max-width: 768px) {
    gap: 4px;
  }
`;


import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		background: {
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(0,0,0,0.5)',
			position: 'fixed',
			top: '0',
			zIndex: '12',
			display: 'flex',
			overflow: 'auto',
		},

		container: {
			width: '640px',
			height: '692px',
			opacity: '1',
			textAlign: 'center',
			position: 'relative',
			backgroundColor: '#FFFFFF',
			borderRadius: '22px',
			justifyContent: 'center',
			alignItems: 'center',
			margin: 'auto',
			[theme.breakpoints.down('md')]: {
				width: '590px',
				height: '460px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '383px',
				height: '654px',
			},
		},
		containerYes: {
			position: 'relative',
			textAlign: 'center',
			width: '512px',
			height: '667px',
			[theme.breakpoints.down('md')]: {
				width: '590px',
				height: '460px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '383px',
				height: '542px',
			},
		},
		containerConfirmYes: {
			position: 'relative',
			textAlign: 'center',
			width: '512px',
			height: '512px',
			[theme.breakpoints.down('md')]: {
				width: '590px',
				height: '460px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '383px',
				height: '542px',
			},
		},
		containerMdf: {
			position: 'relative',
			width: 512,
			height: 667,
			textAlign: 'center',

			[theme.breakpoints.down('md')]: {
				width: 590,
				height: 480,
			},
			[theme.breakpoints.down('sm')]: {
				width: 383,
				height: 542,
			},
		},
		imgClose: {
			position: 'absolute',
			right: '15px',
			top: '15px',
			zIndez: '1',
			cursor: 'pointer',
		},
		containerData: {
			height: '100%',
			width: '90%',
			// outline: '2px solid blue',
			margin: 'auto',
		},
		divText: {
			// outline: '2px solid blue',
			width: '100%',
			heigh: '350px',
			textAlign: 'center',
			paddingTop: '20px',
			[theme.breakpoints.down('md')]: {
				paddingTop: '10px',
			},
		},
		buttonYes: {
			marginTop: '40px',
		},
		title: {
			fontSize: '35px',
			fontWeight: '800',
			color: '#000000',
			margin: '0px',
		},
		titleYes: {
			lineHeight: '43px',
			[theme.breakpoints.down('md')]: {
				lineHeight: '25px',
				fontSize: '25px',
				width: '50%',
				margin: 'auto',
				paddingTop: '10px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '80%',
				paddingTop: '20px',
			},
		},
		titleNo: {
			lineHeight: '43px',
			[theme.breakpoints.down('md')]: {
				lineHeight: '30px',
				fontSize: '25px',
				width: '70%',
				paddingTop: '15px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '80%',
				padding: '30px 0px 15px 0px',
			},
		},
		image: {
			width: '300px',
			height: '300px',
			[theme.breakpoints.down('md')]: {
				width: '137px',
				height: '126px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '176px',
				height: '161px',
			},
		},
		imageYes: {
			width: '332px',
			height: '333px',
			[theme.breakpoints.down('md')]: {
				width: '293px',
				height: '234px',
			},
		},
		imageNo: {
			width: '280px',
			height: '333px',
			[theme.breakpoints.down('md')]: {
				width: '240px',
				height: '200px',
			},
		},
		imageNoDos: {
			width: '300px',
			height: '300px',
			[theme.breakpoints.down('md')]: {
				width: '240px',
				height: '200px',
			},
		},
		imageModificar: {
			width: 445,
			heigh: 297,
			[theme.breakpoints.down('md')]: {
				width: 293,
				height: 195,
			},
		},
		text: {
			fontSize: '14px',
			color: '#434343',
			textAlign: 'left',
			marginTop: '0px',
		},
		textYes: {
			fontSize: '16px',
			textAlign: 'center',
			lineHeight: '20px',
			marginBottom: '40px',
			[theme.breakpoints.down('md')]: {
				marginTop: '0px',
				marginBottom: '20px',
			},
			[theme.breakpoints.down('sm')]: {
				marginBottom: '25px',
			},
		},
		textNo: {
			fontSize: 18,
			textAlign: 'center',
			lineHeight: '22px',
			marginTop: '0px',
			marginBottom: '30px',
			[theme.breakpoints.down('md')]: {
				marginTop: '0px',
				fontSize: '14px',
				lineHeight: '18px',
				marginBottom: '30px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '90%',
				marginBottom: '25px',
				margin: 'auto',
			},
		},

		divButton: {
			height: 'auto',
			marginBottom: '20px',
		},
		selection: {
			width: '330px',
			margin: 'auto',
			display: 'flex',
			gap: '5px',
			alignItems: 'center',
			textAlign: 'left',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		selectionMdf: {
			width: 340,
		},
		contained: {
			background: '#E61737',
		},
		outlined: {
			background: 'white',
		},
		buttonContained: {
			width: '14px',
			height: '14px',
			padding: '1px',
			borderRadius: '50%',
			// marginRight: '6px',
			border: '1px solid #707070',
			// outline: '#707070',
		},
		button: {
			width: '90%',
			textTransform: 'none',
			background: '#E61737',
			padding: '15px 120px',
			height: '54px',
			border: 'none',
			cursor: 'pointer',
			margin: 'auto',
			[theme.breakpoints.down('md')]: {
				width: '65%',
				height: '42px',
				marginTop: '0px',
				padding: '10px 100px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: 'auto',
				marginTop: '15px',
			},
		},
		span: {
			textTransform: 'none',
			textAlign: 'center',
			color: 'white',
			fontSize: '18px',
			width: '100%',
		},
		nota: {
			width: '100%',
			color: '#414141',
			fontSize: 15,
			marginTop: 30,
			[theme.breakpoints.down('md')]: {
				display: 'none',
			},
			[theme.breakpoints.down('sm')]: {
				display: 'block',
				fontSize: 12,
				textAlign: 'left',
				marginTop: 30,
			},
		},
		divButtonMdf: {
			[theme.breakpoints.down('sm')]: {
				margin: '30px auto',
			},
		},
		textMdf: {
			textAlign: 'center',
			fontSize: 16,
			[theme.breakpoints.down('md')]: {
				fontSize: 14,
			},
		},
		buttonMdf: {
			margin: 'auto',
		},
	})
);

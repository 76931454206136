import { useState, useEffect, useRef, Fragment } from 'react';
import FullwidthCarousel from '../../components/FullWidthCarousel';
import Benevits from '../../components/Benevits';
import { benevitsAPI } from '../../api/modules/benevits';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Newsletter from '../../components/Newsletter/Newsletter';
import ArticlesThumbnails from '../../components/ArticlesThumbnails';
// @ts-ignore
import { filterBenevitAPI } from '../../api/modules/filtroBenevit';
// @ts-ignore
import { makeStyles, createStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material/styles';

import NewsletterConfirmYes from '../../components/Newsletter/NewsletterConfirmYes';
import NewsletterConfirmNo from '../../components/Newsletter/NewsletterConfirmNo';

import Tour from '../../components/Tour/tour';
import PopupApp from '../../components/PopupApp';

import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../../firebase';
import { notificationAPI } from '../../api/modules/notification';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Categories from '../../components/Categories/Categories';
import FeaturedBenevits from '../../components/FeaturedBenevits/FeaturedBenevits';
import { Snackbar, SnackbarContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		div: {
			height: 80,
			[theme.breakpoints.down('sm')]: {
				height: 74,
			},
		},
		containerToast: {
			backgroundColor: '#FFF',
			color: '#000'
		},
		toast: { position: 'fixed', top: 100, right: 0, zIndex: 2 },
		toastTitle: { color: 'red' }
	})
);

const activarMensajes = async (id) => {
	const token = await getToken(messaging, {
		vapidKey: 'BD2ru56DuP0EGRHF5p9aSY45oLWisvkq0Orks5eHW5dAUubNHJkT9nveFac8eTerDhoekkDWPySU33YxlqaOCEc'
	}).catch(error => console.log("Tuviste un error al generar el token"));

	if (token) {
		const tk = localStorage.setItem("notification", token)
		notificationAPI.saveToken(token, id)
	}
	if (!token) { console.log("No tienes token!") }
}

type benevit = {
	benevit: object[];
};

const LandingNoUser = () => {
	const classes = useStyles();
	const [unOrLocked, setUnOrlocked] = useState<Array<benevit>>([]);
	const { user, isOpenNewsletterModal } = useSelector(
		(state: RootState) => state.session
	);
	const [disabledSearch, setDisabledSearch] = useState(true);
	const [idSearch, setIdSearch] = useState<undefined | string>();
	const [idBrand, setIdBrand] = useState<number | string>('0');
	const [idFilter, setIdFilter] = useState<number | string>('0');
	const [idCategory, setIdCategory] = useState<number | string>('0');
	const [idTerritory, setIdTerritory] = useState<number | string>('0');
	const [isLoadingBenevits, setIsLoadingBenevits] = useState<boolean>(false);
	const token: string | undefined = user?.token;
	const id: number | undefined = user?.id;
	const signInCount: number | undefined = user?.sign_in_count;
	const subscribedToNewsletter: boolean | undefined = user?.subscribed_to_newsletter;
	const tour: boolean | undefined = user?.tour;
	const searchRefBus = useRef<HTMLHeadingElement>(null);
	const [openTour, setOpenTour] = useState<boolean>(false);
	const [openPopupApp, setopenPopupApp] = useState<boolean>(false);
	const [openToast, setOpenToast] = useState<boolean>(false);
	const [openSnack, setOpenSnack] = useState<boolean>(false);
	const [notificationTitle, setNotificationTitle] = useState<string | undefined>("");
	const [notificationMessage, setNotificationMessage] = useState<string | undefined>("");

	const action = (
		<Fragment>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={() => setOpenSnack(false)}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</Fragment>
	);


	const handleDisabledSearch = () => {
		setTimeout(() => {
			setDisabledSearch(false);
		}, 3000);
	};
	const getBenevitWithStatus = (
		benevitArr: object[],
		unlocked: boolean | undefined
	) => {
		let newBenevitArr: any = [];
		benevitArr.map((benevitObj: any) => {
			newBenevitArr.push({ ...benevitObj, unlocked });
		});
		return newBenevitArr;
	};
	const getBenevits = async () => {
		setDisabledSearch(true);
		setUnOrlocked([]);
		if (user === null) {
			setIsLoadingBenevits(true);
			const data = await benevitsAPI.benevitsNoAuth();
			const lockedNoUser = data.locked;
			lockedNoUser.sort((a, b) => b.id - a.id);
			let allBenevitsArray: any = [...getBenevitWithStatus(lockedNoUser, false)];
			setUnOrlocked(allBenevitsArray);
			setIsLoadingBenevits(false);
		} else {
			setIsLoadingBenevits(true);
			const res = await benevitsAPI.getAllBenevits(token);
			const locked = res?.data?.locked;
			const unLocked = res?.data?.unlocked;

			let lockedBenevitsArray: any = [
				...getBenevitWithStatus(locked, false),
			];
			lockedBenevitsArray.sort((a, b) => b.id - a.id);
			let unlockedBenevitsArray: any = [
				...getBenevitWithStatus(unLocked, true),
			];
			unlockedBenevitsArray.sort((a, b) => b.id - a.id);
			let allBenevits: any = lockedBenevitsArray.concat(unlockedBenevitsArray);
			let index = 0;
			allBenevits.map((benevitObj: any) => {
				if (benevitObj.id === 1000) {
					index = allBenevits.indexOf(benevitObj);
					allBenevits.splice(index, 1);
					allBenevits.splice(0, 0, benevitObj);
				}
			});
			setUnOrlocked(allBenevits);
			setIsLoadingBenevits(false);
		}
		handleDisabledSearch();
	};
	const getBrandBenevitLanding = async () => {
		try {
			setDisabledSearch(true);
			setUnOrlocked([]);
			setIsLoadingBenevits(true);
			const res = await filterBenevitAPI.landingBrandCarouselFilter(token, idBrand);
			const lockedFilter = res.data.locked;
			const unlockedFilter = res.data.unlocked;
			let lockedBenevitsFilterArr: any = [
				...getBenevitWithStatus(lockedFilter, false),
			];
			lockedBenevitsFilterArr.sort((a, b) => b.id - a.id);
			let unlockedBenevitsFilterArr: any = [
				...getBenevitWithStatus(unlockedFilter, true),
			];
			unlockedBenevitsFilterArr.sort((a, b) => b.id - a.id);
			let allBenevits: any = lockedBenevitsFilterArr.concat(unlockedBenevitsFilterArr);
			let index = 0;
			allBenevits.map((benevitObj: any) => {
				if (benevitObj.id === 1000) {
					index = allBenevits.indexOf(benevitObj);
					allBenevits.splice(index, 1);
					allBenevits.splice(0, 0, benevitObj);
				}
			});
			setUnOrlocked(allBenevits);
			setIsLoadingBenevits(false);
			handleDisabledSearch();
		} catch (error) {

		}

	};

	const getBenevitFilterLanding = async () => {
		setDisabledSearch(true);
		setUnOrlocked([]);
		setIsLoadingBenevits(true);
		try {
			const res = await filterBenevitAPI.landingBenevitFilter(
				token,
				idFilter,
				idTerritory,
				idCategory
			);

			const lockedFilter = res.data.locked;
			const unlockedFilter = res.data.unlocked;
			let lockedBenevitsFilterArr: any = [
				...getBenevitWithStatus(lockedFilter, false),
			];
			lockedBenevitsFilterArr.sort((a, b) => b.id - a.id);
			let unlockedBenevitsFilterArr: any = [
				...getBenevitWithStatus(unlockedFilter, true),
			];
			unlockedBenevitsFilterArr.sort((a, b) => b.id - a.id);
			let allBenevits: any = lockedBenevitsFilterArr.concat(unlockedBenevitsFilterArr);
			let index = 0;
			allBenevits.map((benevitObj: any) => {
				if (benevitObj.id === 1000) {
					index = allBenevits.indexOf(benevitObj);
					allBenevits.splice(index, 1);
					allBenevits.splice(0, 0, benevitObj);
				}
			});
			setUnOrlocked(allBenevits);
			setIsLoadingBenevits(false);
			handleDisabledSearch();
		} catch (error) {

		}

	};

	const getSearchBenevitFilter = async () => {
		setDisabledSearch(true);
		setUnOrlocked([]);
		if (!idSearch) {
			getBenevits();
		} else {
			setIsLoadingBenevits(true);
			const res = await filterBenevitAPI.searchlandingBenevitFilter(
				token,
				idSearch
			);
			let locked: any = [];
			let unlocked: any = [];
			res.map((benevitObj: any) => {
				if (benevitObj.unlocked) {
					unlocked.push(benevitObj);
				} else {
					locked.push(benevitObj);
				}
			});

			locked.sort((a, b) => b.id - a.id);
			unlocked.sort((a, b) => b.id - a.id);
			let allBenevits: any = locked.concat(unlocked);
			let index = 0;
			allBenevits.map((benevitObj: any) => {
				if (benevitObj.id === 1000) {
					index = allBenevits.indexOf(benevitObj);
					allBenevits.splice(index, 1);
					allBenevits.splice(0, 0, benevitObj);
				}
			});
			setUnOrlocked(allBenevits);
			setIsLoadingBenevits(false);
		}
		handleDisabledSearch();
	};

	const setSelectBenevit = (arg: number | string) => {
		setIdBrand(arg);
		if (searchRefBus !== null) {
			searchRefBus.current?.scrollIntoView({ behavior: 'smooth' });
		}
	};

	useEffect(() => {
		getBenevits();
	}, [user]);

	useEffect(() => {
		getBenevitFilterLanding();
	}, [idFilter, idTerritory, idCategory]);

	useEffect(() => {
		getSearchBenevitFilter();
	}, [idSearch]);

	useEffect(() => {
		getBrandBenevitLanding();
	}, [idBrand]);

	useEffect(() => {
		if (user != null) {
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(function (position) {
					benevitsAPI.location(position.coords.latitude, position.coords.longitude, token);
				});
			} else {
				console.log("Ubicación no disponible");
			}
		}
	}, [user]);

	useEffect(() => {
		if (user != null) {
			//const saved = localStorage.getItem('tour');
			if (tour == null && subscribedToNewsletter != null && isOpenNewsletterModal == false) {
				//localStorage.setItem('tour', 'true');
				setOpenTour(true);
			}/*else if(saved === 'true'){
				setOpenTour(true);
			}*/
		}
	}, [user]);

	useEffect(() => {
		if (user != null && tour != null && subscribedToNewsletter != null && isOpenNewsletterModal == false) {
			const saved = localStorage.getItem('popupApp');
			if (saved === null || saved === 'false') {
				setopenPopupApp(true);
			}
		}
	}, [user]);

	useEffect(() => {
		const saved = localStorage.getItem('notification');
		if (saved == null && user != null) {
			activarMensajes(id);
		}
	}, [user])

	useEffect(() => {
		onMessage(messaging, message => {
			setNotificationTitle(message.notification?.title);
			setNotificationMessage(message.notification?.body);
			setOpenSnack(true)
		})
	})

	return (
		<div className='container'>
			<div className='row pt-4 mb-4'>
				<div className='col-12'>
					<FullwidthCarousel />
				</div>
			</div>

			<div className='row mb-5'>
				<div className='col-12'>
					<Categories />
				</div>
			</div>

			<FeaturedBenevits />

			<div className='row mb-5'>
				<div className='col-12'>
					<ArticlesThumbnails />
					{/* <SocialIcons isCard /> */}
				</div>
			</div>

			<div className='row mb-5'>
				<div className='col-12'>
					<Benevits
						title="Benevits populares"
						benevits={unOrLocked}
						isLoadingBenevits={isLoadingBenevits}
						limit={8}
					/>
				</div>
			</div>

			{/* {user === null && <WelcomeText />} */}

			{user !== null &&
				subscribedToNewsletter === null && <Newsletter />}
			{user !== null &&
				subscribedToNewsletter === false &&
				isOpenNewsletterModal && <NewsletterConfirmYes />}
			{user !== null &&
				subscribedToNewsletter &&
				isOpenNewsletterModal && <NewsletterConfirmNo />}

			<div ref={searchRefBus} />

			{/* {user !== null && (
				<BenevitsFilter
					disabled={disabledSearch}
					idFilter={idFilter}
					idCategory={idCategory}
					idTerritory={idTerritory}
					idBrand={idBrand}
					setIdBrand={setIdBrand}
					setIdFilter={setIdFilter}
					setIdSearch={setIdSearch}
					setIdCategory={setIdCategory}
					setIdTerritory={setIdTerritory}
				/>
			)} */}

			{openTour && <Tour setopenPopupApp={setopenPopupApp} />}
			{openPopupApp && <PopupApp />}

			{/* Notification */}

			<Snackbar
				style={{ background: '#FFF' }}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={5000}
				open={openSnack}
				onClose={() => setOpenSnack(false)}
			>
				<SnackbarContent
					className={classes.containerToast}
					message={<div><strong>{notificationTitle}</strong><br />{notificationMessage}</div>}
					action={action}
				/>
			</Snackbar>
		</div>
	);
};

export default LandingNoUser;

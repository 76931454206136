import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			marginTop: 100,
			[theme.breakpoints.down('md')]: {
				marginTop: 40,
			},
		},
		benevitsToUnlockContainer: {
			margin: '80px 0px',
		},
		buttonBack: {
			margin: '60px auto',
			marginBottom: 80,
			fontSize: 18,
			width: '30%',
			height: 54,
			borderRadius: 14,
			background: '#E61737',
			color: 'white',
			border: '1px solid #EEEEEE',
			cursor: 'pointer',
			fontFamily: 'Montserrat',
			[theme.breakpoints.down('md')]: {
				width: '50%',
			},
		},
	})
);

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, useFormikContext } from 'formik';
import Dropdown from '../form/Dropdown/Dropdown';
import { FiltersContainer, queryButtonStyles, checkboxStyles, Label, Icon, resetButtonStyles, Search } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Checkbox from '../form/Checkbox/Checkbox';
import Button from '../Button';
import { categoriesAPI } from '../../api/modules/categories';
import { marcasAPI } from '../../api/modules/marcas';
import { REPUBLIC_STATES } from '../../utils/republicStates';
import { SORT_OPTIONS } from '../../utils/sortOptions';
import { convertUrlParamsToFormValues, convertArrayToOptions, setFormValues, convertValuesToQueryParams, handleGeolocation, findStateByName } from '../../utils/searchFunctions';
import SearchBanner from '../SearchBanner/SearchBanner';

const FormObserver = ({ setIsLoading, formik }: any) => {
  const navigate = useNavigate();
  const { values, setFieldValue }: any = useFormikContext();
  const didMount = useRef(false);
  const { user } = useSelector(
    (state: RootState) => state.session
  );

  // If searchParams, set form values
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setFormValues(convertUrlParamsToFormValues(params), setFieldValue);
  }, []);

  // If form values change, set searchParams
  useEffect(() => {
    const urlQuery = window.location.search;
    const formQuery = convertValuesToQueryParams(values);

    // If "near" value changes
    if (values.near) {
      setIsLoading(true);
      handleGeolocation(user).then((currentState) => {
        console.log("RESPONSE:", currentState);
        if (currentState?.id) {
          formik.setFieldValue('states', [currentState?.id]);
          // navigate(convertValuesToQueryParams({ ...values, states: [currentState?.id] }));
        }
      }).catch((error) => {
        console.log(error);
      }
      ).finally(() => {
        setIsLoading(false);
      });
    }

    // If toogle changes
    if (didMount.current) {
      if (urlQuery !== formQuery) {
        navigate(convertValuesToQueryParams(values));
      }
    } else {
      didMount.current = true;
    }

  }, [values]);

  return null;
};

const SearchFilters = ({ searchParams, setSearchParams, result, setIsLoading, ...props }: any) => {
  const { user } = useSelector((state: RootState) => state.session);
  const [categories, setCategories] = useState<any>([]);
  const [brands, setBrands] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const urlQuery = new URLSearchParams(window.location.search);
  const initialValues = {
    categories: [],
    brands: [],
    states: [],
    near: '',
    order: '',
    query: ''
  };

  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const _fetchFilters = async () => {
    const resCategories = await categoriesAPI.categoriesFiltro();
    const resBrands = await marcasAPI.marcasFiltro();

    Promise.all([resCategories, resBrands]).then((response) => {
      setCategories(convertArrayToOptions(response[0]?.data, 'categories'));
      setBrands(convertArrayToOptions(response[1]?.data, 'brands'));
    });
  }

  // Get filters from endpoints
  useEffect(() => {
    _fetchFilters();
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
      }}
      validateOnChange={true}
      enableReinitialize
    >
      {(formik) => {

        return (
          <Search open={open}>
            <Form>
              <FormObserver setIsLoading={setIsLoading} formik={formik} />

              <SearchBanner
                query={urlQuery.get('query')}
                categories={categories}
                brands={brands}
                values={formik?.values}
                data={result}
              />

              {isMobile && (
                <div className='col-12 px-3'>
                  <Button variant="outline" type="button" onClick={() => setOpen(!open)} className='w-100'>Filtros</Button>
                </div>
              )}

              <FiltersContainer open={open}>
                {urlQuery.get('query') && (
                  <Button
                    variant="solid"
                    type="button"
                    onClick={() => formik.setFieldValue('query', '')}
                    style={queryButtonStyles}
                  >
                    "<Label>{urlQuery.get('query')}</Label>"
                    <Icon src={process.env.PUBLIC_URL + "/assets/icons/cross.svg"} alt="close" />
                  </Button>
                )}
                <Dropdown options={categories} name="categories">Categoría</Dropdown>
                <Dropdown options={brands} name="brands">Marca</Dropdown>
                <Dropdown options={convertArrayToOptions(REPUBLIC_STATES, 'states')} name="states">Estado</Dropdown>

                {user != null && (
                  <Checkbox id="near" name="near" style={checkboxStyles} inputStyles={checkboxStyles.input} value="checked">Cerca de mí</Checkbox>
                )}

                {urlQuery.toString().length > 0 && (
                  <Button
                    variant="outline"
                    type="button"
                    onClick={() => formik.resetForm()}
                    style={resetButtonStyles}
                  >
                    <i className="bi bi-trash"></i>
                    Borrar filtros
                  </Button>
                )}

                <div className='ms-auto mr-0'>
                  <Dropdown options={SORT_OPTIONS} name="order">Ordenar</Dropdown>
                </div>

                {isMobile && (
                  <div className='actions d-flex flex-column mt-auto mb-0 gap-3'>
                    <Button variant="solid" type="button" onClick={() => setOpen(!open)}>Aplicar</Button>
                  </div>
                )}
              </FiltersContainer>
            </Form>
          </Search>
        )
      }}
    </Formik>
  );
};

export default SearchFilters;
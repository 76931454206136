import { benevitsAPI } from '../api/modules/benevits';
import axios from 'axios';
import { REPUBLIC_STATES } from './republicStates';

export const convertUrlParamsToFormValues = (searchParams:any) => {
  const values = {
    categories: searchParams.get('categories')?.split(',') || [],
    brands: searchParams.get('brands')?.split(',') || [],
    states: searchParams.get('states')?.split(',') || [],
    near: searchParams.get('near') || '',
    query: searchParams.get('query') || '',
    order: searchParams.get('order') || ''
  };

  return (values);
};

// Convert form values to query params
export const convertValuesToQueryParams = (values:object) => {
  const params = new URLSearchParams(window.location.search);

  if(values) {
    // For values
    for (const [key, value] of Object.entries(values) ) {
      if(value && value.length > 0) {
        if(params.has(key)) {
          params.set(key, value.toString());
        } else {
          // If key is not "near" append to params
          if(key !== 'near') params.append(key, value.toString());
        }
      } else {
        if(params.has(key) && (key === 'categories' || key === 'brands' || key === 'states' || key === 'near' || key === 'query' || key === 'order')) {
          params.delete(key);
        }
      }
    }
  }
  
  return '?' + params.toString();
};

// Convert to options array (data: fetch response, name: from formik initial values)
export const convertArrayToOptions = (data:any, name:string) => {
  return data.map((item:any) => ({
    id: item?.name,
    value: item?.id,
    label: item?.name,
    name: name
  }));
};

// Set form values (values: formik values, setFieldValue: formik setFieldValue)
export const setFormValues = (values:any, setFieldValue:any) => {
  for(const [key, value] of Object.entries(values)) {
    setFieldValue(key, value);
  }
};

// Get category by id
export const getCategoryById = (id:number, categories:any) => {
  return categories.find((category:any) => category.value == id);
};

// Get brand by id
export const getBrandById = (id:number, brands:any) => {
  return brands.find((brand:any) => brand.id == id);
}

// Get geolocation
export const handleLocation = (user:any) => {
  let coordinates:any = null;
  
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(async (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      // Update user location
      // if(user) benevitsAPI.location( latitude, longitude, user?.token);
      console.log(latitude, longitude)
      coordinates = { latitude, longitude };
    }, (error) => {
      console.log(error)
      coordinates = null;
    });

    return coordinates;
  };

  return coordinates;
};

// Get geolocation in promise
export const getPosition = (options?:any): Promise<any> => {
  return new Promise((resolve, reject) => 
    navigator.geolocation.getCurrentPosition(resolve, reject, options));
};

// Handle geolocation
// Get user position and send it to endpoint and return state information
export const handleGeolocation = (user:any): Promise<any> => {

  const allFetch = async () => {
    // Get user position
    const position:any = await getPosition(user).then((res) => res).catch((err) => err);
    
    // If there is a position
    if(position) {
      // Send it to endpoint
      await benevitsAPI.location(position?.coords?.latitude, position?.coords?.longitude, user?.token).then((res) => {
        // OK response
        // console.log(res);
      }).catch((err) => {
        console.error(err)
      });

      // Get state information by position
      const stateData = await axios.post('https://xussh8lzj1.execute-api.us-east-1.amazonaws.com/default/location-reverse-geocode',{
        "lat": position?.coords?.latitude,"lon":position?.coords?.longitude
      });

      return findStateByName(stateData.data?.body?.Region, REPUBLIC_STATES);
    };
  };

  if(user) {
    // Set state information in redux
    return new Promise((resolve, reject) => allFetch().then((res) => resolve(res)).catch((err) => reject(err)));
  }

  return new Promise((resolve, reject) => resolve("No user"));
};

export const findStateByName = (name:string, states:any) => {
  return states.find((state:any) => state.code === name);
};
import CarouselMarcas from '../CarouselMarcas/CarouselMarcas';
import EmailBar from '../EmailBar/EmailBar';
import { useStyles } from './styles';

const WelcomeText = () => {
	const classes = useStyles();
	return (
		<div className={classes.divWelcome}>
			<p className={classes.title}>
				<b>Beneficios que te acompañan</b>
			</p>
			<p className={classes.text}>
				Socio Infonavit es la plataforma para derechohabientes del Infonavit, en
				la que puedes conseguir descuentos y promociones en los mejores comercios
				cerca de ti.{' '}
			</p>
			<p className={classes.text}>
				No necesitas contar con un crédito ni cumplir con otro requisito.
			</p>
			<p className={classes.text}>¡Sólo debes registrarte!</p>
			<EmailBar />
			<CarouselMarcas />
		</div>
	);
};

export default WelcomeText;

import { useStyles } from './styles';
import { useState } from 'react';
import { Alert } from '@mui/material';
import { AlertColor } from '@mui/material';
import { nssValid } from '../../utils/expressions';
import { emailVerificationAPI } from '../../api/modules/emailVerification';
import Login from '../Login/Login';
import Register from '../Register/Register';

interface EmailValue {
	email: string;
}
interface IAlert {
	show: boolean;
	message: string;
	severity: AlertColor;
}
const EmailBar = () => {
	const classes = useStyles();
	const [openLogin, setOpenLogin] = useState<boolean>(false);
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [emailData, setEmailData] = useState<EmailValue>({
		email: '',
	});
	const [emailError, setEmailError] = useState({ valid: false, msg: '' });
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		show: false,
		severity: 'success',
	});

	const [email, setEmail] = useState<string>('');
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setEmail(value);
		if (name === 'email') {
			let validate = { valid: false, msg: '' };
			validate = nssValid(value);
			setEmailError(validate);
		}
		setEmailData({ ...emailData, [name]: value });
	};
	const handleKeypress = (e: any) => {
		//it triggers by pressing the enter key
		if (e.key === 'Enter') {
			handleVerifyEmail();
		}
	};
	const handleVerifyEmail = async () => {
		if (!emailError.valid) {
			setAlert({
				show: true,
				message: 'Introduzca un NSS válido.',
				severity: 'warning',
			});
			setTimeout(() => {
				setAlert({
					show: false,
					message: '',
					severity: 'warning',
				});
			}, 5000);
			return;
		} else {
			try {
				const res = await emailVerificationAPI.emailVerification(emailData.email);
				if (res?.data?.email_is_registered === true) {
					setOpenLogin(!openLogin);
				} else {
					setOpenRegister(!openRegister);
				}
				setAlert({
					show: false,
					message: '',
					severity: 'warning',
				});
			} catch (error) {
				setAlert({
					show: true,
					message:
						'Por el momento el servicio no se encuentra disponible, intenta más tarde',
					severity: 'warning',
				});
			}
		}
	};
	return (
		<>
			<div className={classes.container}>
				<div className={classes.div}>
					<div className={classes.divInput}>
						<input
							maxLength={11}
							name="email"
							type="email"
							onChange={handleInputChange}
							className={classes.input}
							onKeyPress={handleKeypress}
							required
							placeholder="Ingresa tu NSS para iniciar sesión"
						/>
						{/* <label className={classes.label}>
							<span className={classes.name}>
								Ingresa tu NSS para iniciar sesión
							</span>
						</label> */}
					</div>
					<button onClick={handleVerifyEmail} className={classes.button}>
						Empieza ya
					</button>
				</div>
				{alert.show && (
					<Alert severity={alert.severity} style={{ margin: '10px auto' }}>
						{alert.message}
					</Alert>
				)}
			</div>

			{openLogin && (
				<Login
					email={email}
					openLogin={openLogin}
					setOpenLogin={setOpenLogin}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
			{openRegister && (
				<Register
					email={email}
					openLogin={openLogin}
					setOpenLogin={setOpenLogin}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
		</>
	);
};

export default EmailBar;

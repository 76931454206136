// Dependencies
import React from 'react';

// Styles
import { StyledButton } from './styles';

interface IButton {
	label?: string | React.ReactElement;
	onClick?: (e: any) => void;
	variant?: 'solid' | 'outline';
	size?: 'small' | 'medium' | 'large';
	state?: 'default' | 'loading' | 'success' | 'error';
	style?: React.CSSProperties;
	disabled?: boolean;
	fluid?: boolean;
	children?: any;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	as?: string;
	href?: string;
	rel?: string;
	target?: string;
}

// const CustomButton = (props: IButton) => {
// 	const { onClick, label, variant, styles, disabled } = props;
// 	const classes = useStyles();
// 	return (
// 		<button
// 			onClick={onClick}
// 			style={{ ...styles, backgroundColor: disabled ? '#CDCDCD' : '' }}
// 			disabled={disabled}
// 			className={
// 				variant === 'solid'
// 					? classes.buttonLogInSolid
// 					: classes.buttonLogInOutlined
// 			}
// 		>
// 			{label}
// 		</button>
// 	);
// };

const Button = ({ onClick, label, children, variant, state, style, size, disabled, fluid, className, ...props }: IButton) => {

	return (
		<StyledButton
			onClick={onClick}
			variant={variant || 'solid'}
			size={size || 'medium'}
			state={state || 'default'}
			fluid={fluid}
			disabled={disabled}
			style={style}
			className={`${variant === 'solid' ? 'solid' : 'outline'} ${className}`}
			{...props}
		>
			{children || label}
		</StyledButton >
	);
}

export default Button;

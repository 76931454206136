import { createPortal } from "react-dom";
import styles from "./Modal.module.scss";
import Button from "../Button";

const Modal = ({
  data,
  show,
  setShow,
  children,
  className,
  container,
  showClose,
  ...props
}: any) => {
  const ModalStructure = () => (
    <div
      className={`${styles.background} ${className ? className : ""}`}
    >
      <div
        className={`${styles.content} container mx-auto relative ${container ? container?.className : ""
          }`}
        {...container}
      >
        {showClose &&
          <Button
            variant="solid"
            onClick={() => setShow(false)}
            className={styles.close}
          >
            <span>Cerrar</span>
            <img
              src="/assets/images/icons/cross.svg"
              width={24}
              height={24}
              alt="close"
            />
          </Button>
        }
        {children}
      </div>
    </div>
  );

  return <>{show && createPortal(<ModalStructure />, document.body)}</>;
};

export default Modal;

import styled from '@emotion/styled';
import { css } from '@emotion/react';

const solid = css`
  --bg-color: #DD0528;
  --color: white;
  border: 0;
`;

const outline = css`
  --bg-color: transparent;
  --color: #DD0528;
  border: 1px solid var(--color);
`;

export const StyledButton: any = styled('button')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  height: 42px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color);
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--bg-color);

  &:hover {
    filter: brightness(0.9);
  }

  &:active {
    filter: brightness(0.8);
  }

  &:focus {
    box-shadow: 0 0 0 2px #DD0528;
  }

  &:disabled {
    filter: brightness(0.5);
    cursor: not-allowed;
  }

  ${(props: any) => props.variant === 'solid' && solid}
  ${(props: any) => props.variant === 'outline' && outline}
  ${(props: any) => props.fluid && css`width: 100%;`}
  ${(props: any) => props.size === 'small' && css`height: 32px;`}

  ${(props: any) => props.state === 'success' && css`
    --bg-color: #C7E7B3;
    --color: #006E2E;
  `}
`;
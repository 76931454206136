import React, { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStyles, titleStyles } from './styles';
import Button from '../Button';
import Login from '../Login/Login';
import QuestionsAndAnswersModal from '../QuestionsAndAnswersModal/QuestionsAndAnswersModal';
import CustomSkeleton from '../CustomSkeleton';
import qAndAImage from '../../assets/img/actividades-a-financiar.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import VideoPlaybackModal from '../VideoPlaybackModal/VideoPlaybackModal';
import { benevitsAPI } from '../../api/modules/benevits';
import MoreInformationModal from '../VideoPlaybackModal/components/MoreInformationModal/MoreInformationModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ItemCard from '../ItemCard';
import WithoutBenevits from '../WithoutBenevits/WithoutBenevits';
import { Title } from '../../assets/styles/Typography';
import { Section } from '../../assets/styles/Layout';

type Props = {
	benevits: Object[];
	title?: string;
	sizeTitle?: number;
	isLoadingBenevits: boolean;
	limit?: number;
	alignTitle?:
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'match-parent';
};

export default function Benevits({
	benevits,
	isLoadingBenevits,
	title,
	alignTitle = 'center',
	sizeTitle = 25,
	...props
}: Props) {
	const [limit, setLimit] = useState<number>(props.limit ?? 20);
	const [activeBenevit, setActiveBenevit] = useState<any>({});
	const [slideState, setSlideState] = useState<any>({});
	const [campaingModal, setCampaingModal] = useState<boolean>(false);
	const [campaingModalVideo, setCampaingModalVideo] = useState<boolean>(false);
	const [campaingType, setCampaingType] = useState<number>(0);
	const [campaing, setCampaing] = useState<any>();
	const [openMoreInfoModal, setOpenMoreInfoModal] = useState<boolean>(false);
	const { user } = useSelector((state: RootState) => state.session);
	const classes = useStyles();
	const titleClasses = titleStyles({ alignTitle, sizeTitle });
	const navigate = useNavigate();
	const [data, setData] = useState(benevits.slice(0, limit));
	const token: string | undefined = user?.token;
	const [openModalMessage, setOpenModalMessage] = useState<boolean>(false);
	const [openModalCP, setOpenModalCP] = useState<boolean>(false);
	const [zipCode, setZipCode] = useState<string | undefined>();
	const [openLogin, setOpenLogin] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [isMobile, setIsMobile] = useState(false);

	const onCloseModalCampaing = (): void => setCampaingModal(false);

	const handleSetBenevit = (benevit: any) => {
		if (!user) {
			setOpenLogin(!openLogin);
			return;
		}

		setActiveBenevit(benevit);

		if (!benevit.unlocked && benevit.id != 1000) {
			benevitsAPI.getQuestion(token).then((response) => {
				let data = { ...response.data };
				const questSort = (data.questions || []).sort(
					(item1: any, item2: any) => {
						if (item1.question_index > item2.question_index) {
							return 1;
						}
						if (item1.question_index < item2.question_index) {
							return -1;
						}
						return 0;
					}
				);
				setCampaing({ ...data, questions: [...questSort] });
				if (response.data.campaign_type === 'video') {
					setCampaingModalVideo(!campaingModalVideo);
				} else {
					setCampaingModal(!campaingModal);
				}
			});
		} else if(!benevit.unlocked && benevit.id === 1000){
			setOpenModalCP(!openModalCP)
		}else {
			navigate(`/info-benevit/${benevit.slug}`);
		}
	};

	const handleGoToBenevit = () => {
		setOpenMoreInfoModal(false);
		navigate(`/info-benevit/${activeBenevit.slug}`);
	};

	const handleUnlockBenevit = async (answers?: any[], slide?: any) => {
		await benevitsAPI.unlockBenevit(activeBenevit.id, token);
		
		if (campaingModalVideo) {
			await benevitsAPI.updateMetrics(campaing?.metrics?.id as number, token);
			setCampaingModalVideo(false);
		} else {
			await benevitsAPI.updateAnsweredMetrics(
				campaing?.metrics?.id as number,
				token
			);
			setCampaingModal(!campaingModal);
			if ((answers?.length ?? 0) > 0) {
				/* aplicar logica para guardar las respuestas */
				answers?.forEach((answer, index) => {
					benevitsAPI.postAnswers(
						campaing?.questions[index].id as number,
						answer.option_value,
						token
					);
				});
			}
		}
		handleGoToBenevit();
	};

	const handleMoreInfo = () => {
		setOpenMoreInfoModal(true);
	};

	const handleCloseCP = () => {
		setOpenModalCP(false);
	};

	const handleCloseMessage = () => {
		setOpenModalMessage(false);
		benevits.filter(b => {
			if (b['id'] === 1000) {
				b['unlocked'] = true;
				//console.log(b);
			}
		})
		//navigate(`/info-benevit/${activeBenevit.slug}`);
	};

	const handleAnswer = async () => {
		await benevitsAPI.unlockBenevit(1000, token);
		await benevitsAPI.postAnswers(
			1000 as number,
			zipCode,
			token
		);
			
		if ("geolocation" in navigator) {
			console.log("Available");
			navigator.geolocation.getCurrentPosition(function(position) {
				benevitsAPI.location( position.coords.latitude,position.coords.longitude, token);
				});
			} else {
			console.log("Not Available");
		}
		setOpenModalCP(false);
		setOpenModalMessage(true);
		setZipCode('');
	};

	const _renderItems = (items) => items.map((item, index) => (
		<div
			key={index}
			className="d-flex col-lg-3 col-md-4 col-sm-6 col-xs-12"
		>
			<ItemCard
				data={ item }
				handleSetBenevit={ handleSetBenevit }
			/>
		</div>
	));

	// On mount
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

	// Update data
	useEffect(() => {
		setData(benevits.slice(0, limit));
	}, [limit, benevits]);

	return (
		<>
			<Section>
				{!isLoadingBenevits ? (
					<>
						{!data.length ? (
							<WithoutBenevits />
						) : (
							<>
								{title && 
									<div className="row">
										<div className="col-12">
											<Title className="m-0">{title}</Title>
										</div>
									</div>
								}

								<div className="row row-gap-3">
									{_renderItems(data)}
										
									{ // Load more button
										limit < benevits.length && (
										<div className='d-flex col-12 justify-content-center'>
											<Button variant='outline' onClick={() => setLimit(limit + 20)}> 
												Cargar más...
											</Button>
										</div>
									)}
								</div>
							</>
						)}
					</>
				) : (
					<CustomSkeleton />
				)}
			</Section>

			{campaingModalVideo && (
				<VideoPlaybackModal
					isMobileVideo={isMobile}
					open={campaingModalVideo}
					url={campaing}
					// url={campaing?.content}
					campaingURL={campaing?.url}
					onCancel={() => setCampaingModalVideo(!campaingModalVideo)}
					handleUnlockBenevit={handleUnlockBenevit}
					light={false}
					// onMoreInfoClick={handleMoreInfo}
				/>
			)}
			{campaingModal && (
				<QuestionsAndAnswersModal
					open={campaingModal}
					onBackButton={onCloseModalCampaing}
					questionImage={qAndAImage}
					question={campaing}
					handleUnlockBenevit={handleUnlockBenevit}
				/>
			)}
			{openMoreInfoModal && (
				<MoreInformationModal
					// open={openMoreInfoModal}
					slide={slideState}
					onGoToBenevit={handleGoToBenevit}
				/>
			)}
			{(
				<Dialog open={openModalCP} onClose={handleCloseCP} maxWidth="xs">
				<DialogTitle>Estás a un paso</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Necesitas verificar tu ubicación. Introduce tu código postal
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="cp"
						label="C.P."
						type="text"
						fullWidth
						variant="standard"
						value={zipCode}
						onChange={(newValue) => setZipCode(newValue.target.value)} 
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleAnswer}>Aceptar</Button>
					<Button onClick={handleCloseCP}>Cancelar</Button>
				</DialogActions>
				</Dialog>
			)}
			{(
				<Dialog open={openModalMessage} onClose={handleCloseMessage} maxWidth="xs">
					<DialogTitle>¡Gracias por participar!</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Revisa tu correo en los próximos días para ver si eres uno de los afortunados ganadores.
							<br/>
							¡No olvides revisar tu spam! A veces, nuestros correos pueden terminar allí por error.
							<br/><br/>
							¡Buena suerte!
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseMessage}>Aceptar</Button>
					</DialogActions>
				</Dialog>
			)}

			{openLogin && (
				<Login
					email={email}
					openLogin={openLogin}
					setOpenLogin={setOpenLogin}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}
		</>
	);
}

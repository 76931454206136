import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { textAlign } from '@mui/system';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		cardContainer: {
			border: '1px solid #DEE0E4',
			borderRadius: 8,
			overflow: 'hidden',
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'column',

			[theme.breakpoints.down('lg')]: {
				flexDirection: 'row',
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			},
		},

		containerImage: {
			padding: '50px',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			gap: '10px',
			alignSelf: 'stretch',
			height: 400,
			width: '100%',
			position: 'relative',
			display: 'flex',
			flexShrink: 0,

			[theme.breakpoints.down('lg')]: {
				padding: '10px',
				width: 100,
				height: 100,
				alignItems: 'center',
				margin: 'auto'
			},
		},

		imgBenevit: {
			width: '100%',
			height: 'auto',
			minHeight: 200,
			objectFit: 'contain',

			[theme.breakpoints.down('lg')]: {
				minHeight: 'initial'
			},
		},

		textDescription: {
			borderTop: '1px solid #DEE0E4',
			padding: '20px',
			color: '#000',
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: '140%',

			[theme.breakpoints.down('lg')]: {
				fontSize: '12px',
				fontStyle: 'normal',
				fontWeight: 400,
				lineHeight: '140%',
				border: 0,
				padding: '10px 16px',
			}
		}
	})
);

import React from 'react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Slider from 'react-slick';
import prev from '../../assets/img/buttom/prevA.png';
import next from '../../assets/img/buttom/nextA.png';
import {
	Slide,
	SlideImage,
	SlideContent,
	Heading,
	Description,
	Arrow,
	ArrowIcon,
} from './styles';
import { carouselAPI } from '../../api/modules/carousel';
import './styles.css';

type bannerObj = {
	active: true | false;
	background: null | string;
	background_movile?: string;
	button_text: string;
	description: null | string;
	has_buttom: null | boolean;
	id: number;
	link: string;
	name: null | string;
	position: number;
	title: null | string;
};

const CustomArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<Arrow direction={props.direction}>
			<ArrowIcon src={props.arrow} onClick={onClick} />
		</Arrow>
	);
};

const FullwidthCarousel = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const [banners, setBanners] = useState<Array<bannerObj>>([]);
	const [isMobile, setIsMobile] = useState(false);

	const settings = {
		adaptiveHeight: true,
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		nextArrow: <CustomArrow arrow={next} direction="next" />,
		prevArrow: <CustomArrow arrow={prev} direction="prev" />,
		autoplay: true,
		autoplaySpeed: 3000,
		appendDots: (dots: any) => <ul className="banner-dots">{dots}</ul>
	};

	const bannerFunction = async () => {
		const res = await carouselAPI.banners();
		const banner = res.data.result;
		setBanners(banner);
	};

	// Render image for desktop or mobile
	const RenderImage = ({ slide }) => (
		<SlideImage
			src={isMobile ? slide.background_movile : slide.background}
			alt={slide.name}
		/>
	);

	//Render slide content
	const _renderSlides = (slides) => slides.map((slide) => (
		<Slide
			key={slide.id}
			onClick={
				slide.has_buttom
					? () => window.open(slide.link, '_blank')
					: undefined
			}
		>
			{/* Banner image */}
			<RenderImage slide={slide} />

			{(slide?.title || slide?.description) && (
				<SlideContent>
					{slide?.title && <Heading>{slide.title}</Heading>}

					{slide?.description && (
						// String to html
						<Description
							dangerouslySetInnerHTML={{
								__html: slide.description,
							}}
						/>
					)}
				</SlideContent>
			)}
		</Slide>
	));

	// On mount
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		bannerFunction();
	}, [user]);

	return (
		<Slider {...settings} className="banner">
			{_renderSlides(banners)}
		</Slider>
	);
};

export default FullwidthCarousel;

import { useTheme } from '@mui/material/styles';
import {
	Box,
	Button,
	CardMedia,
	Grid,
	Modal,
	Paper,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	useMediaQuery,
} from '@mui/material';
import { useMemo, useState } from 'react';
import Text from '../Text/Text';
import { useStyles, CustomButton } from './styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// @ts-ignore
import UnlockBenevitBackdrop from '../UnlockBenevitBackdrop/UnlockBenevitBackdrop';
import MoreInformationModal from '../VideoPlaybackModal/components/MoreInformationModal/MoreInformationModal';
import ModalLoading from '../ModalLoading';
import Navbar from '../Navbar';

// This could be on props after removing mock data

// This could be on props after removing mock data
const CORRECT_ANSWER: string = 'Todas las anteriores.';

const MOCK_ANSWERS: string[] = [
	'Remodelar una casa',
	'Comprar una casa nueva o existente.',
	'Construir una casa.',
	// 'Todas las anteriores.'
	CORRECT_ANSWER,
];
type Props = {
	open: boolean;
	onBackButton: () => void;
	handleUnlockBenevit: (answers?: any[], slide?: any) => void;
	questionImage?: string;
	question: {
		poll_type: string;
		questions: [
			{
				question_text: string;
				image: string;
				options: [
					{
						id: number;
						is_correct: boolean;
						option_value: string;
						image?: string;
						next_question_index: number;
					}
				];
				answer_type: {
					id: number;
					name: string;
					key: string;
				};
			}
		];
	};
};

const QuestionsAndAnswersModal = ({
	open,
	onBackButton,
	questionImage,
	handleUnlockBenevit,
	question,
}: Props) => {
	const classes = useStyles();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const isPortrait = useMediaQuery(theme.breakpoints.down('md'));
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const [selectedAnswers, setSelectedAnswers] = useState<any[]>(() => []);
	const [recordAnswers, setRecordAnswers] = useState<any[]>([]);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
	const [answerValue, setAnswerValue] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [questionHasBeenAnswered, setQuestionHasBeenAnswered] =
		useState<boolean>(false);

	const handleAnswer = (
		// e: React.MouseEvent<HTMLElement, MouseEvent>,
		answer: any
	): void => {
		if (
			question?.questions[currentQuestionIndex]?.answer_type?.key ===
			'multiple_selection'
		) {
			if (selectedAnswers.filter((e: any) => e.id === answer.id).length > 0) {
				const newAnswers = selectedAnswers.filter(
					(el: any) => el.id !== answer.id
				);
				setSelectedAnswers(newAnswers);
			} else {
				const newAnswers = [...selectedAnswers, answer];
				setSelectedAnswers(newAnswers);
			}
		} else {
			setSelectedAnswers([answer]);
		}
	};

	const handleContinue = (e?: React.MouseEvent<HTMLElement, MouseEvent>): void => {
		if (e) {
			e.preventDefault();
		}
		setQuestionHasBeenAnswered(true);
		setLoading(true);
		setTimeout(() => {
			let answersToRecord: any[] = [];
			const key = question?.questions[currentQuestionIndex]?.answer_type.key;
			if (key === 'user_input') {
				answersToRecord = [{ option_value: answerValue }];
			} else if (key === 'multiple_choice') {
				answersToRecord = [...selectedAnswers];
			} else if (key === 'multiple_selection') {
				let valuesRes = '';
				selectedAnswers.forEach((value) => {
					valuesRes += value.option_value + ', ';
				});
				answersToRecord = [{ option_value: (valuesRes || '').slice(0, -2) }];
			}
			// if (question?.questions.length === currentQuestionIndex + 1) {
			// 	handleUnlockBenevit([...recordAnswers, ...answersToRecord]);
			// } else {
			// 	setRecordAnswers([...recordAnswers, ...answersToRecord]);
			// 	setSelectedAnswers([]);
			// 	setQuestionHasBeenAnswered(false);
			// 	setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
			// }
			//  else if (
			// 	question?.questions[currentQuestionIndex + 1].answer_type.key === 'slide'
			// ) {
			// 	handleUnlockBenevit(
			// 		[...recordAnswers, ...answersToRecord],
			// 		question?.questions[currentQuestionIndex + 1]
			// 	);
			// }
			// if (question?.questions.length === currentQuestionIndex + 1) {
			// 	handleUnlockBenevit([...recordAnswers, ...answersToRecord]);
			// } else {
			// 	setRecordAnswers([...recordAnswers, ...answersToRecord]);
			// 	setSelectedAnswers([]);
			// 	setQuestionHasBeenAnswered(false);
			// 	setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
			// }

			// console.log('ENTRA QUI ====>', { answersToRecord });
			const recordAnswersFlag = [...recordAnswers, ...answersToRecord];
			// console.log('ENTRA QUI X2 ====>', { answersToRecord });
			if (
				(selectedAnswers[0] || []).next_question_index === -1 ||
				question?.questions[currentQuestionIndex]?.options.length < 1
			) {
				handleUnlockBenevit([...recordAnswersFlag]);
			} else {
				setRecordAnswers([...recordAnswersFlag]);
				setSelectedAnswers([]);
				setQuestionHasBeenAnswered(false);
				setCurrentQuestionIndex(
					(selectedAnswers[0] || []).next_question_index - 1
				);
			}
			setLoading(false);
		}, 2500);
	};

	const getButtonClassNameHelper = (answer: any, index: number): string => {
		const hasSelectedAnswer: boolean = selectedAnswers.length > 0;

		const answerIsSelected: boolean =
			selectedAnswers.filter((e: any) => e.id === answer.id).length > 0;

		const answerIsCorrect: boolean =
			question?.questions[currentQuestionIndex]?.options[index]?.is_correct;
		if (!hasSelectedAnswer) {
			return classes.nonSelectedAnswerItem;
		} else if (hasSelectedAnswer && !questionHasBeenAnswered && answerIsSelected) {
			return classes.selectedAnswerItem;
		} else if (
			question?.poll_type !== 'demystification' &&
			hasSelectedAnswer &&
			questionHasBeenAnswered &&
			answerIsSelected
		) {
			return classes.selectedAnswerItem;
		} else if (
			question?.poll_type === 'demystification' &&
			questionHasBeenAnswered &&
			answerIsCorrect &&
			answerIsSelected
		) {
			return classes.correctAnswerItem;
		} else if (
			question?.poll_type === 'demystification' &&
			questionHasBeenAnswered &&
			!answerIsCorrect &&
			answerIsSelected
		) {
			return classes.incorrectAnswerItem;
		} else if (
			question?.poll_type === 'demystification' &&
			questionHasBeenAnswered &&
			answerIsCorrect &&
			!answerIsSelected
		) {
			return classes.correctAnswerItem;
		}
		return classes.nonSelectedAnswerItem;
	};
	const continueButtonDisabled = useMemo(
		() => selectedAnswers.length < 1 || questionHasBeenAnswered || !selectedAnswers,
		[selectedAnswers, questionHasBeenAnswered]
	);

	return (
		<>
			<Modal
				open={open}
				// hideBackdrop
				className={classes.modalRoot}
				// BackdropComponent={() => <UnlockBenevitBackdrop />}
			>
				<>
					{question?.questions[currentQuestionIndex]?.answer_type.key ===
						'multiple_choice' ||
					question?.questions[currentQuestionIndex]?.answer_type.key ===
						'multiple_selection' ? (
						<Paper
							className={classes.modalSubContainer}
							style={{
								boxSizing: 'border-box',
								border: '1px solid rgba(0,0,0,0.1)',
							}}
						>
							<Text
								fontSize={isDesktop ? 20 : 18}
								color="#414141"
								width={isDesktop ? 360 : '100%'}
								textAlign="center"
							>
								<span
									id="instructions"
									dangerouslySetInnerHTML={{
										__html: question?.questions[currentQuestionIndex]
											?.question_text,
									}}
								/>
							</Text>
							<Box className={classes.questionImageContainer}>
								{question?.questions[currentQuestionIndex]?.image && (
									<CardMedia
										component="img"
										src={
											question?.questions[currentQuestionIndex]
												?.image
										}
										className={classes.questionImage}
									/>
								)}
							</Box>
							{/* <ToggleButtonGroup
							orientation="vertical"
							fullWidth
							value={selectedAnswers}
							exclusive
							onChange={handleAnswer}
							aria-label="text alignment"
							className={
								questionHasBeenAnswered
									? classes.answersGroupHasntBeenAnswered
									: ''
							}
						> */}
							<Grid
								container
								style={{
									overflowY: 'auto',
									boxSizing: 'border-box',
								}}
								spacing={0}
							>
								{question?.questions[currentQuestionIndex]?.options
								.sort((a, b) => a.id - b.id)
								.map(
									(answer: any, index: number) => (
										<Grid
											xs={12}
											key={index}
											md={answer.image ? 4 : 12}
										>
											<button
												// value={index}
												className={`${
													classes.answerItem
												} ${getButtonClassNameHelper(
													answer,
													index
												)}`}
												// fullWidth
												// disabled={questionHasBeenAnswered}
												onClick={() => handleAnswer(answer)}
											>
												{answer.image ? (
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
															flexDirection: 'column',
														}}
													>
														<CardMedia
															component="img"
															src={answer.image}
															style={{ width: 100 }}
														/>
														<Text
															fontSize={isDesktop ? 17 : 15}
															margin={'auto'}
															textAlign="center"
														>
															{answer.option_value}
														</Text>
													</div>
												) : (
													<Text
														fontSize={isDesktop ? 17 : 15}
														margin={'auto'}
														textAlign="center"
													>
														{answer.option_value}
													</Text>
												)}
												{/* If question has been answered, then show icons for correct/incorrect answers */}
												{question?.poll_type ===
													'demystification' &&
													questionHasBeenAnswered &&
													(!answer.is_correct ? (
														// If failed answer, cross icon turns white
														question?.questions[
															currentQuestionIndex
														]?.options[0] === answer && // selectedAnswers ??
														question?.questions[
															currentQuestionIndex
														]?.options[0] ? ( // selectedAnswers ??
															// Incorrect and selected answer
															<CancelIcon
																className={
																	classes.failedIcon
																}
															/>
														) : (
															// Incorrect but not selected answers
															<HighlightOffIcon
																// color="error"
																className={
																	classes.checkIcon
																}
															/>
														)
													) : (
														// Correct answer
														<CheckCircleIcon
															fontSize="large"
															className={classes.checkIcon}
														/>
													))}
											</button>
										</Grid>
									)
								)}
							</Grid>
							{/* </ToggleButtonGroup> */}

							<CustomButton
								className={`${classes.continueButton}`}
								variant="contained"
								fullWidth
								onClick={handleContinue}
								disabled={continueButtonDisabled}
								disableElevation
								style={{ borderRadius: 10 }}
							>
								<Text
									fontSize={isDesktop ? 16 : 13}
									color={continueButtonDisabled ? '#a7a7a7' : '#ffffff'}
								>
									Continuar
								</Text>
							</CustomButton>
							<CustomButton
								fullWidth
								onClick={onBackButton}
								disableElevation
								disableTouchRipple
								disableFocusRipple
								style={{ marginTop: 14 }}
							>
								<Text
									fontSize={isDesktop ? 16 : 17}
									fontWeight={700}
									color="#E61737"
								>
									Cancelar
								</Text>
							</CustomButton>
						</Paper>
					) : question?.questions[currentQuestionIndex]?.answer_type.key ===
					  'slide' ? (
						<MoreInformationModal
							slide={question?.questions[currentQuestionIndex]}
							onGoToBenevit={handleContinue}
						/>
					) : (
						<Paper className={classes.modalSubContainer}>
							<Box className={classes.questionImageContainer}>
								<Text
									fontSize={isDesktop ? 20 : 18}
									color="#414141"
									width={isDesktop ? 360 : '100%'}
									textAlign="center"
								>
									<span
										id="instructions"
										dangerouslySetInnerHTML={{
											__html: question?.questions[
												currentQuestionIndex
											]?.question_text,
										}}
									/>
								</Text>
								<Text fontSize={isPortrait || isMobile ? 14 : 20}>
									<TextField
										id="answer_value"
										label="Respuesta"
										variant="outlined"
										name="email"
										type="text"
										onChange={(e) => setAnswerValue(e.target.value)}
										className={classes.input}
										margin="normal"
										value={answerValue}
									/>
								</Text>
								<Button
									className={`${classes.button} ${classes.continueButton}`}
									variant="contained"
									fullWidth
									onClick={handleContinue}
									disabled={!answerValue}
									disableElevation
								>
									<Text
										fontSize={isDesktop ? 17 : 13}
										color={!answerValue ? '#a7a7a7' : '#ffffff'}
									>
										Continuar
									</Text>
								</Button>
								<CustomButton
								fullWidth
								onClick={onBackButton}
								disableElevation
								disableTouchRipple
								disableFocusRipple
								style={{ marginTop: 14 }}
								>
									<Text
									fontSize={isDesktop ? 16 : 17}
									fontWeight={700}
									color="#E61737"
									>
										Cancelar
									</Text>
								</CustomButton>
							</Box>
						</Paper>
					)}
				</>
			</Modal>
			<ModalLoading loading={loading} />
		</>
	);
};

export default QuestionsAndAnswersModal;

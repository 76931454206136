import React, { useEffect } from 'react';
import Category from '../Category/Category';
import { categoriesAPI } from '../../api/modules/categories';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { StyledCategories, Title, CategoriesRow } from './styles';

const Categories = () => {
  const { user } = useSelector((state: RootState) => state.session);
  const token: string | undefined = user?.token;
  const [categories, setCategories] = React.useState<any>([]);

  // Render category component
  const _renderCategories = (categories: any) => categories.map(
    (category: any) => <Category key={category?.id} data={category} className="category" />
  );

  // Get categories
  useEffect(() => {
    const getCategories = async () => {
      const res = await categoriesAPI.categoriesFiltro();
      const categories = await res?.data;
      setCategories(categories);
    };

    getCategories();

  }, []);

  // If categories is empty, show loading
  if (!categories.length) return null;

  return (
    <StyledCategories>
      <Title className="m-0">Encuentra tu categoría favorita</Title>
      <CategoriesRow>
        {_renderCategories(categories)}
      </CategoriesRow>
    </StyledCategories>
  );
};

export default Categories;
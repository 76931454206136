import API from '..';
class MarcasAPI extends API {
	marcasFiltro(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v3/public/allies-select`,
				method: 'GET',
			}),
			true
		);
	}
	marcasFiltroExp(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v3/public/allies-select-exp`,
				method: 'GET',
			}),
			true
		);
	}
}
export const marcasAPI = new MarcasAPI('');

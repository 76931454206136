import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modalRoot: {
			width: '100%',
			// height: '100%',
			overflow: 'auto',
			display: 'flex',
		},
		modalContainer: {
			margin: 'auto',
			padding: '40px',
			boxShadow: '0px 17px 27px #9D636329 !important',
			borderRadius: '22px !important',
			[theme.breakpoints.down('md')]: {
				marginTop: '15vh',
				padding: '31px',
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: '11vh',
			},
		},
		mobileModalContainer: {
			margin: 'auto',
			padding: '40px',
			boxShadow: '0px 17px 27px #9D636329 !important',
			borderRadius: '22px !important',
			[theme.breakpoints.down('md')]: {
				marginTop: '15vh',
				padding: '31px',
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: '15vh',
			},
		},
		videoContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '80vw',
			height: '80vh',
			backgroundColor: 'black',
			// [theme.breakpoints.down('md')]: {
			// 	height: '60vh',
			// },
			[theme.breakpoints.down('md')]: {
				height: '60vh',
			},
			[theme.breakpoints.down('sm')]: {
				height: '21vh',
			},
		},
		mobileVideoContainer: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			width: '80vw',
			height: '80vh',
			backgroundColor: 'black',
			[theme.breakpoints.down('md')]: {
				height: '60vh',
			},
			[theme.breakpoints.down('sm')]: {
				height: '66vh',
			},
		},
		playerButtonsContainer: {
			display: 'flex',
			gap: 6,
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
		cancelButtonContainer: {
			paddingTop: '36px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				paddingTop: '32px',
			},
		},
	})
);

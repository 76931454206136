import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		backgroundDiv: {
			width: '100%',
			height: '100%',
			backgroundColor: 'rgba(0,0,0,0.5)',
			position: 'fixed',
			top: '0',
			left: '0',
			zIndex: '10',
			display: 'flex',
		},
		container: {
			opacity: '1',
			position: 'relative',
			backgroundColor: '#FFFFFF',
			borderRadius: '22px',
			width: '512px',
			height: '640px',
			margin: 'auto',
			justifyContent: 'center',
			alignItems: 'center',

			[theme.breakpoints.down('md')]: {
				height: '412px',
				width: '560px',
				padding: 0,
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				maxWidth: '420px',
				height: 'auto',
				paddingBottom: 20,
			},
		},
		closeButton: {
			position: 'absolute',
			width: '22px',
			height: '22px',
			top: '10px',
			right: '8px',
			cursor: 'pointer',
		},
		div: {
			height: '98%',
			width: '85%',
			margin: '10px auto',
			textAlign: 'center',
			[theme.breakpoints.down('md')]: {
				width: '90%',
				margin: '5px auto',
			},
			[theme.breakpoints.down('sm')]: {
				width: '90%',
			},
		},
		divText: {
			width: '100%',
			height: '210px',
			marginBottom: '10px',
			padding: '0px',
			[theme.breakpoints.down('md')]: {
				height: '150px',
				marginBottom: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				height: 'auto',
				marginBottom: '10px',
			},
		},
		title: {
			fontSize: '25px',
			fontWeight: '700',
			margin: '0',
			paddingTop: '30px',
			[theme.breakpoints.down('md')]: {
				fontSize: '26px',
				paddingTop: '10px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '24px',
				paddingTop: '40px',
				lineHeight: '30px',
			},
		},
		text: {
			color: '#414141',
			fontSize: '16px',
			width: '95%',
			margin: '28px auto',
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
				margin: '16px auto',
				width: '100%',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				marginBottom: 12,
			},
		},
		containerInput: {
			marginBottom: '15px',
			height: 'auto',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			gap: 0,
			[theme.breakpoints.down('md')]: {
				height: '75px',
				flexDirection: 'row',
				gap: 10,
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				height: '135px',
				gap: 0,
			},
		},
		divInput: {
			marginTop: '10px',
			marginBottom: '10px',
			outline: '1px solid #EEEEEE',
			height: '54px',
			borderRadius: '14px',
			border: 'none',
			width: '100%',
			position: 'relative',
			overflow: 'hidden',
			[theme.breakpoints.down('md')]: {
				marginTop: '5px',
				width: '48%',
				height: '41px',
				borderRadius: '9px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				height: '41px',
				marginTop: '10px',
			},
		},
		input: {
			borderRadius: '14px',
			width: '100%',
			height: '100%',
			color: '#080808',
			paddingLeft: '10px',
			fontSize: '16px',
			outline: 'none',
			background: '#F8F8F8',
			border: 'none',
			[theme.breakpoints.down('md')]: {
				height: 57,
			},
			'& .MuiOutlinedInput-root': {
				borderRadius: '14px',
				'& .MuiInputBase-input': {
					[theme.breakpoints.down('sm')]: {
						padding: '12px 10px',
					},
				},
				//
				'& .focus-visible': {
					outline: '1px solid #EEEEEE',
					background: '#FFFFFF',
					border: 'none',
				},
				'& fieldset': {
					border: 'none',
				},
				'&:hover fieldset': {
					border: 'none',
				},
				'&.Mui-focused fieldset': {
					border: 'none',
				},
			},
			// '&:focus + label span, &:valid + label span': {
			// 	transform: 'translateY(-18px)',
			// 	fontSize: '10px',
			// },
			// '&:focus ': {
			// 	background: '#FFFFFF',
			// },
			// '&:valid': {
			// 	background: '#FFFFFF',
			// },
			// [theme.breakpoints.down('md')]: {
			// 	borderRadius: '9px',
			// 	fontSize: '12px',
			// 	'&:focus + label span, &:valid + label span': {
			// 		transform: 'translateY(-15px)',
			// 		fontSize: '7px',
			// 	},
			// },
			// [theme.breakpoints.down('sm')]: {
			// 	'&:focus + label span, &:valid + label span': {
			// 		transform: 'translateY(-13px)',
			// 	},
			// },
		},
		// label: {
		// 	fontSize: '18px',
		// 	position: 'absolute',
		// 	bottom: '12px',
		// 	left: '10px',
		// 	width: '100%',
		// 	height: '100%',
		// 	pointerEvents: 'none',
		// 	color: '#080808',
		// 	[theme.breakpoints.down('md')]: {
		// 		fontSize: '14px',
		// 		bottom: '8px',
		// 	},
		// 	[theme.breakpoints.down('sm')]: {
		// 		bottom: '6px',
		// 	},
		// },
		// name: {
		// 	position: 'absolute',
		// 	bottom: '5px',
		// 	left: '0px',
		// 	transition: 'all 0.3s ease',
		// 	color: '#989898',
		// },

		aviso: {
			color: '#8E8E8E',
			fontSize: '15px',
			textDecorationColor: '#8E8E8E',
			marginBottom: '20px',
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				marginBottom: 5,
			},
		},

		button: {
			fontFamily: 'Montserrat',
			background: '#E61737',
			borderRadius: '14px',
			width: '100%',
			textTransform: 'none',
			margin: '10px 0px',
			height: '54px',
			border: 'none',
			fontSize: '18px',
			color: 'white',
			cursor: 'pointer',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				height: '41px',
				fontSize: '13px',
				margin: '0px',
				borderRadius: '9px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				margin: '0px',
			},
		},
		cuenta: {
			color: '#000000',
			margin: '5px auto',
		},
		accede: {
			color: '#E61737',
			margin: 'auto',
			textDecoration: '#E61737 underline',
			cursor: 'pointer',
		},
	})
);

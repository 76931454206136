import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			color: '#000000',
			fontSize: 25,
			fontWeight: 800,
			textAlign: 'center',
			paddingTop: 40,
			paddingBottom: 40,
			marginBottom: 10,
			[theme.breakpoints.down('md')]: {
				fontSize: 16,
				paddingBottom: 0,
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: 16,
				paddingBottom: 5,
			},
		},
		titleInicio: {
			marginTop: 60,
			fontWeight: 800,
			[theme.breakpoints.down('sm')]: {
				marginTop: 20,
			},
		},
		containerCarousel: {
			width: '90%',
			margin: 'auto',
			paddingBottom: 10,
			[theme.breakpoints.down('md')]: {
				width: '85%',
			},
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			},
			'& .slick-slider': {
				padding: '10px',
				'& .slick-list': {
					padding: '20px',
				},
				'& .slick-slide': {
					padding: '20px',
					paddingLeft: '10px',
				},
			},
		},
		containerCarouselInicio: {
			width: '70%',
			margin: 'auto',
			[theme.breakpoints.down('md')]: {
				width: '80%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '70%',
			},
			'& .slick-slider': {
				padding: '10px',
				'& .slick-list': {
					padding: '20px',
				},
				'& .slick-slide': {
					padding: '20px',
					paddingLeft: '10px',
				},
			},
		},
		zoom: {
			'&:hover': {
				width: '100px',
				transitionDelay: '500ms',
			},
		},
		box: {
			background: 'white',
			width: '180px !important ',
			height: '180px',
			borderRadius: '23px',
			marginLeft: '4%',
			boxShadow: '0px 17px 27px #9d636317',
			transition: 'all 300ms',
			'&:hover': {
				transform: 'scale(1.12)',
			},
			[theme.breakpoints.down('lg')]: {
				width: '160px !important',
				height: '160px !important',
				marginLeft: '6%',
			},
			[theme.breakpoints.down('md')]: {
				width: '99px !important',
				height: '99px !important',
			},
			[theme.breakpoints.down('sm')]: {
				marginLeft: '9%',
			},
			[theme.breakpoints.down('sm')]: {
				boxShadow: '0px 10px 20px #9d636317',
				width: '85px !important',
				height: '84px !important',
				marginBottom: '10px',
			},
		},
		boxInicio: {
			width: '135px !important',
			height: 126,
			background: 'white',
			borderRadius: '23px',
			marginLeft: '8%',
			boxShadow: '0px 17px 27px #9d636317',
			transition: 'all 300ms',
			'&:hover': {
				transform: 'scale(1.05)',
				boxShadow: '0px 20px 30px #9d636317',
				cursor: 'pointer',
			},
			[theme.breakpoints.down('lg')]: {
				width: '100px !important',
				height: 100,
				marginLeft: '6%',
			},
			[theme.breakpoints.down('md')]: {
				width: '90px !important',
				height: 90,
				marginLeft: '15%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '84px !important',
				height: 84,
				marginLeft: '7%',
				marginBottom: 0,
			},
		},
		selected: {
			outline: '1px solid #87CEEB',
			width: '135px !important',
			height: 126,
			background: 'white',
			borderRadius: '23px',
			marginLeft: '8%',
			boxShadow: '0px 0px 7px #87CEEB',
			transition: 'all 300ms',
			'&:hover': {
				transform: 'scale(1.12)',
			},
			[theme.breakpoints.down('lg')]: {
				width: '100px !important',
				height: 100,
				marginLeft: '6%',
			},
			[theme.breakpoints.down('md')]: {
				width: '90px !important',
				height: 90,
				marginLeft: '15%',
			},
			[theme.breakpoints.down('sm')]: {
				width: '84px !important',
				height: 84,
				marginLeft: '7%',
				marginBottom: 0,
			},
		},
		imgMarca: {
			width: '91%',
			objectFit: 'contain',
			margin: '50px auto',
			[theme.breakpoints.down('lg')]: {
				margin: '50px auto',
			},
			[theme.breakpoints.down('md')]: {
				margin: '27px auto',
			},
			[theme.breakpoints.down('sm')]: {
				margin: '24px auto',
			},
		},
		imgMarcaInicio: {
			width: '91%',
			objectFit: 'contain',
			margin: '35px auto',
			[theme.breakpoints.down('md')]: {
				margin: '25px auto',
			},
			[theme.breakpoints.down('sm')]: {
				margin: '23px auto',
			},
		},

		containerCarouselMobile: {
			display: 'none',
			[theme.breakpoints.down('sm')]: {
				display: 'inline-block',
				width: '75%',
				margin: 'auto',
				transform: 'translate3d(0px, 0px, 0px)',
			},
			'& .slick-slider': {
				padding: '5px 5px 0px 5px',
				'& .slick-list': {
					padding: '20px',
				},
				'& .slick-slide': {
					padding: '20px',
					paddingLeft: '10px',
				},
			},
		},
	})
);

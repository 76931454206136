import styled from '@emotion/styled';

export const DropdownContainer:any = styled('div')`
  position: relative;
`;

export const StyledDropdown:any = styled('div')`
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  z-index: 3;
  flex-direction: column;
  overflow-y: auto;
  max-height: 300px;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #DEE0E4;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.06);
  

  .form-check {
    label {
      white-space: nowrap;
    }
  }
  
  ${(props: any) => props.open ? `display: flex;` : `display: none;`}
`;

export const Icon:any = styled('img')`
  display: inline-flex;
  width: 16px;
  height: 16px;
  object-fit: contain;
  object-position: center;
  margin-left: 16px;
  margin-right: -16px;
  pointer-events: none;

  ${(props: any) => props.open ? `transform: rotateZ(180deg)` : ``}
`;

export const buttonStyles = {
  justifyContent: 'space-between',
  color: 'black',
  borderColor: 'black',
  fontSize: '16px'
};
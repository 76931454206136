import { useContext, useEffect } from "react";
import { ShepherdTour, ShepherdTourContext } from "react-shepherd";
import newSteps from "../../data/steps";
import "shepherd.js/dist/css/shepherd.css";
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { actions } from '../../store/modules/session';
import { newsletterAPI } from '../../api/modules/newsletter';



const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
  },
  useModalOverlay: true,
  //classes: 'class-1 class-2',
  scrollTo: true,
  
};

function TourInstance({ setopenPopupApp }) {
  const tour = useContext(ShepherdTourContext);
  const { user } = useSelector((state: RootState) => state.session);
  const token: string | undefined = user?.token;
	const id: number | undefined = user?.id;
  const dispatch = useDispatch();
  

  useEffect(() => {
    if (tour) {
        setTimeout(function() {
            tour.start();
          }, 2000);
        ['complete', 'cancel'].forEach(event => tour.on(event, async () => {
          await newsletterAPI.updateMemberTour(
            id,
            true,
            token
          ).then(function(data) {
            const userNew = data;
            
            dispatch(actions.setUser({
                   ...userNew.data,
                  email: user?.email,
                  role: user?.role,
                  token: user?.token,
                  sign_in_count: user?.sign_in_count,
                 }))
          });
          //setopenPopupApp(true)
         }));
    }
  }, [tour]);

  return <></>;
}

export default function Tour({ setopenPopupApp }) {
  return (
    <ShepherdTour steps={newSteps} tourOptions={tourOptions}>
      <TourInstance setopenPopupApp={setopenPopupApp}/>
    </ShepherdTour>
  );
}
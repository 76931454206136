import API from '..';

class BenevitsAPI extends API {
	benevitsNoAuth(): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v1/member/landing_benevits_no_auth`,
				method: 'GET',
			}),
			false
		);
	}

	getAllBenevits(token: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		let territory = localStorage.getItem('territory')
			? localStorage.getItem('territory')
			: '0';
		let category = localStorage.getItem('category')
			? localStorage.getItem('category')
			: '0';
		return this.requestWrapper(
			this.request({
				url: `/v1/member/landing_benevits?territory=${territory}&category=${category}`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	getMyBenevits(token: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;

		return this.requestWrapper(
			this.request({
				url: `/v1/member/my_benevits`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	//API_URLS.BENEVITS.GET_ALL_BENEVITS
	//https://qa-api.socioinfonavit.com/api/v1/member/landing_benevits

	getAllyBenevits(
		ally_id: number | string | undefined,
		token: string | undefined
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/allies/${ally_id}/benevits`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	getRandomBenevits(token: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v3/member/benevits/to-unlock`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	getQuestion(token: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/get_content`,
				method: 'GET',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	updateMetrics(metric_id: number, token: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/campaign/update_views/${metric_id}`,
				method: 'PUT',
				data: null,
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	updateAnsweredMetrics(
		poll_metrics_id: number,
		token: string | undefined
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/poll_metrics/${poll_metrics_id}`,
				method: 'PUT',
				data: {
					metric: 'times_answered',
				},
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	postAnswers(
		question_id: number,
		answer_value: any,
		token: string | undefined
	): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/answers`,
				method: 'POST',
				data: {
					answer: {
						question_id,
						answer_value,
					},
				},
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	getBenevit(slug: string, token: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/benevit/${slug}`,
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}
	unlockBenevit(benevit_id: number, token: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v1/member/member_benevits`,
				method: 'POST',
				data: {
					member_benevit: {
						benevit_id: benevit_id,
						state: 2,
						origin: 'web',
					},
				},
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}

	location(lat: number, lon: number, token: string | undefined): Promise<any> {
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `/v3/member/location`,
				method: 'POST',
				data: {
					lat: lat,
					lon: lon,
				},
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
			}),
			true
		);
	}

	updateLike(id: number, token: string | null): Promise<any> {
		console.log(token);
		const x = Math.floor(Math.random() * 500) + 1;
		return this.requestWrapper(
			this.request({
				url: `v1/member/member_benevits/update_like`,
				method: 'POST',
				headers: {
					Authorization: `${token}`,
					'X-Case': Math.round(new Date().getTime() / 1000) * x,
					'X-Trace': x,
				},
				data: {
					id: id,
				},
			}),
			true
		);
	}
}

export const benevitsAPI = new BenevitsAPI('');

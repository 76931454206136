import React, { useState, useEffect } from 'react';
import { DropdownContainer, StyledDropdown, buttonStyles, Icon } from './styles';
import Button from '../../Button';
import CheckBox from '../Checkbox/Checkbox';

const Dropdown = ({ options, name, children, onChange, ...props }:any) => {
  const [isOpen, setIsOpen] = useState(false);

  const _renderOptions = (options:any) => options.map(
    (option:any, index:number) =>
      <CheckBox 
        key={index}
        id={name + option?.id}
        name={name}
        value={option?.value}
        label={option?.label}
        {...onChange}
      />
  );

  // Handle click outsite
  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (!event.target.closest(`#${name}`)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [name]);

  return (
    <DropdownContainer id={name}>
      <Button
        type='button'
        variant="outline"
        style={ buttonStyles }
        onClick={ () => setIsOpen(!isOpen) }
      >
        { children }
        <Icon
          src={process.env.PUBLIC_URL + "/assets/icons/angle-down.svg"}
          alt="angle-down"
          open={ isOpen }
        />
      </Button>

      { (options && options?.length > 0) &&
        <StyledDropdown open={ isOpen }>
          { _renderOptions(options) }
        </StyledDropdown>
      }
    </DropdownContainer>
  );
};

export default Dropdown;
import { Formik } from 'formik';
import MaterialField from '../form/MaterialField/MaterialField';
import Button from '../Button';
import styles from './Searcher.module.scss';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

const Searcher = () => {
  const navigate = useNavigate();

  const handleSubmit = (values: any) => {
    navigate(`/resultados${values.query.length ? `?query=${values.query}` : ''}`);
  };

  const toggleWiggle = (formik) => {
    if (formik?.errors?.query === "") {
      return styles.wiggle;
    }
  };

  return (
    <Formik
      initialValues={{ query: '' }}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className={`${styles.searcher} ${toggleWiggle(formik)}`}>
              <MaterialField
                name="query"
                placeholder="Buscar beneficio"
                type="text"
                icon="search"
                className={styles.field}
              />

              <Button
                variant="solid"
                type="submit"
                className={styles.button}
              >
                Buscar
              </Button>
            </div>
          </form>
        </>
      )}
    </Formik >
  );
}
export default Searcher;
export const REPUBLIC_STATES = [
	// { id: '0', name: 'Ver todos', code: "X" },
	{ id: '34', name: 'Disponibles en todo el país', code: "XX" },
	{ id: '2', name: 'Aguascalientes', code: 'Aguascalientes' },
	{ id: '3', name: 'Baja California', code: 'Baja California' },
	{ id: '4', name: 'Baja California Sur', code: 'Baja California Sur' },
	{ id: '5', name: 'Campeche', code: 'Campeche' },
	{ id: '10', name: 'CDMX', code: 'Ciudad de México' },
	{ id: '9', name: 'Chihuahua', code: 'Chihuahua' },
	{ id: '8', name: 'Chiapas', code: 'Chiapas' },
	{ id: '6', name: 'Coahuila', code: 'Coahuila de Zaragoza' },
	{ id: '7', name: 'Colima', code: 'Colima' },
	{ id: '11', name: 'Durango', code: 'Durango' },
	{ id: '13', name: 'Guerrero', code: 'Guerrero' },
	{ id: '12', name: 'Guanajuato', code: 'Guanajuato' },
	{ id: '14', name: 'Hidalgo', code: 'Hidalgo' },
	{ id: '15', name: 'Jalisco', code: 'Jalisco' },
	{ id: '16', name: 'Estado de México', code: 'México' },
	{ id: '17', name: 'Michoacán', code: 'Michoacán de Ocampo' },
	{ id: '18', name: 'Morelos', code: 'Morelos' },
	{ id: '19', name: 'Nayarit', code: 'Nayarit' },
	{ id: '20', name: 'Nuevo León', code: 'Nuevo León' },
	{ id: '21', name: 'Oaxaca', code: 'Oaxaca' },
	{ id: '22', name: 'Puebla', code: 'Puebla' },
	{ id: '24', name: 'Quintana Roo', code: 'Quintana Roo' },
	{ id: '23', name: 'Querétaro', code: 'Querétaro' },
	{ id: '26', name: 'Sinaloa', code: 'Sinaloa' },
	{ id: '25', name: 'San Luis Potosí', code: 'San Luis Potosí' },
	{ id: '27', name: 'Sonora', code: 'Sonora' },
	{ id: '28', name: 'Tabasco', code: 'Tabasco' },
	{ id: '29', name: 'Tamaulipas', code: 'Tamaulipas' },
	{ id: '30', name: 'Tlaxcala', code: 'Tlaxcala' },
	{ id: '31', name: 'Veracruz', code: 'Veracruz de Ignacio de la Llave' },
	{ id: '32', name: 'Yucatán', code: 'Yucatán' },
	{ id: '33', name: 'Zacatecas', code: 'Zacatecas' },
];

import { useEffect, useState } from "react";
import Benevits from "../Benevits";
import { featuredBenevits } from "../../api/modules/featuredBenevits";
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const FeaturedBenevits = () => {
  const { user } = useSelector((state: RootState) => state.session);
  const [isLoading, setLoading] = useState(true);
  const [title, setTitle] = useState("Los mejores beneficios");
  const [items, setItems] = useState([]);
  const [isActive, setActive] = useState(false);

  const _getFeaturedBenevits = async () => {
    await featuredBenevits.get(user?.token)
      .then((res) => {
        setTitle(res.data.title);
        setItems(res.data.benevits.slice(0, 4));
        setActive(true);
        return res.data;
      })
      .catch((err) => {
        setActive(false);
        return err;
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    _getFeaturedBenevits();
  }, []);

  if (isLoading || !isActive) return null;

  return (
    <div className='row mb-5'>
      <div className='col-12'>
        <div className="featured-benevits">
          <Benevits
            title={title}
            alignTitle="center"
            benevits={items}
            isLoadingBenevits={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default FeaturedBenevits;
import { useState } from 'react';
import { Formik } from 'formik';
import { Form, GroupLabel, RadioGroup, RadioLabel, Required, ErrorMessage, modalContainerStyles, modalImageStyles, modalTextStyles } from './styles';
import MaterialField from '../form/MaterialField/MaterialField';
import Button from '../Button';
import * as Yup from 'yup';
import RadioButton from '../form/RadioButton/RadioButton';
import { REPUBLIC_STATES } from '../../utils/republicStates';
import CheckBox from '../form/Checkbox/Checkbox';
import { alliesAPI } from '../../api/modules/allies';
import Modal from '../Modal/Modal';

const AlliesForm = () => {

  const [showModal, setShowModal] = useState(false);

  const initialValues = {
    contact_name: '',
    phone: '',
    email: '',
    company: '',
    comercial_name: '',
    rfc: '',
    web: '',
    description: '',
    benevits_offer: '',
    extra: '',
    type_sale: '',
    scope_option: '',
    scope: '',
    terms: false,
  };

  const validationSchema = Yup.object({
    contact_name: Yup.string().required("Requerido"),
    phone: Yup.string().matches(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/, "Número de teléfono inválido").required("Requerido"),
    email: Yup.string().email("Correo electrónico inválido").required("Requerido"),
    company: Yup.string().required("Requerido"),
    comercial_name: Yup.string().required("Requerido"),
    description: Yup.string().required("Requerido"),
    benevits_offer: Yup.string().required("Requerido"),
    type_sale: Yup.string().required("Requerido"),
    scope_option: Yup.string().required("Requerido"),
    scope: Yup.string().required("Requerido"),
    terms: Yup.bool().oneOf([true], "Debes aceptar los términos y condiciones"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await alliesAPI.post(values).then((res) => {
        console.log(res);
        setSubmitting(false);
        resetForm();
      }).catch((err) => {
        console.log(err);
        setSubmitting(false);
      }
      ).finally(() => {
        setShowModal(true);
      });
    } catch (error) {
      console.log(error);
      setSubmitting(false);

    }
  };

  const _renderOptions = (states: any) => states.map((state, index) => <option key={state.id} value={state.name}>{state.name}</option>);

  return (
    <>
      <div className='row'>
        <div className='col-12 d-flex flex-column'>
          <span>Completa el formulario para solicitar alianza</span>
          <span>Los campos marcados con * son obligatorios</span>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} className='pb-5'>
            <div className='row'>
              <div className='col-12'>
                <MaterialField label="Nombre del contacto" name="contact_name" type="text" required disabled={formik.isSubmitting} />
              </div>

              <div className='col-6'>
                <MaterialField label="Whatsapp/Teléfono" name="phone" type="text" maxLength={10} required disabled={formik.isSubmitting} />
              </div>

              <div className='col-6'>
                <MaterialField label="Correo electrónico" name="email" type="email" required disabled={formik.isSubmitting} />
              </div>

              <div className='col-12'>
                <MaterialField label="Nombre de la empresa (razón social)" name="company" type="text" required disabled={formik.isSubmitting} />
              </div>

              <div className='col-12'>
                <MaterialField label="Nombre comercial" name="comercial_name" type="text" required disabled={formik.isSubmitting} />
              </div>

              <div className='col-6'>
                <MaterialField label="RFC de la empresa (opcional)" name="rfc" type="text" disabled={formik.isSubmitting} />
              </div>

              <div className='col-6'>
                <MaterialField label="Sitio web (opcional)" name="web" type="text" disabled={formik.isSubmitting} />
              </div>

              <div className='col-12'>
                <MaterialField label="Descripción de la empresa" name="description" type="text" required disabled={formik.isSubmitting} />
              </div>

              <div className='col-12'>
                <MaterialField label="Descuentos o beneficios a ofrecer" name="benevits_offer" type="text" required disabled={formik.isSubmitting} />
              </div>

              <div className='col-12'>
                <MaterialField label="Información adicional que te gustaría compartir con nosotros (opcional)" name="extra" type="text" disabled={formik.isSubmitting} />
              </div>

              <div className='col-12 mb-4'>
                <GroupLabel>Tipo de negocio <Required>*</Required></GroupLabel>
                <RadioGroup>
                  <RadioButton id="businessType1" name="type_sale" label="En línea" value="En línea" disabled={formik.isSubmitting} />
                  <RadioButton id="businessType2" name="type_sale" label="Físico" value="Físico" disabled={formik.isSubmitting} />
                  <RadioButton id="businessType3" name="type_sale" label="Ambos" value="Ambos" disabled={formik.isSubmitting} />
                </RadioGroup>
              </div>

              <div className='col-12 mb-4'>
                <GroupLabel>Alcance a nivel nacional <Required>*</Required></GroupLabel>
                <RadioGroup>
                  <RadioButton id="scope1" name="scope_option" label={<RadioLabel>Toda la república</RadioLabel>} value="Toda la república" onClick={() => formik.setFieldValue('scope', 'Toda la república')} disabled={formik.isSubmitting} />
                  <RadioButton id="scope2" name="scope_option" label={<RadioLabel>Local <small>(Estatal)</small></RadioLabel>} value="Local (Estatal)" disabled={formik.isSubmitting} />
                  <RadioButton id="scope3" name="scope_option" label={<RadioLabel>Otros <small>(Región o diversos estados)</small></RadioLabel>} value="Otros (Región o diversos estados)" disabled={formik.isSubmitting} />
                </RadioGroup>
              </div>

              {formik.values.scope_option === 'Local (Estatal)' && (
                <div className='col-12'>
                  <MaterialField name="scope" label="Selecciona un estado" type="text" as="select" disabled={formik.isSubmitting}>
                    {_renderOptions(REPUBLIC_STATES)}
                  </MaterialField>
                </div>
              )}

              {formik.values.scope_option === 'Otros (Región o diversos estados)' && (
                <MaterialField label="Especifica la región o estados" name="scope" type="textarea" />
              )}

              <div className='col-12 mb-4'>
                <CheckBox id="terms" name="terms" label={<>Acepto los <a href="https://portalmx.infonavit.org.mx/wps/wcm/connect/eca2a7b7-eeb3-4fec-a4d0-cd49f219503c/terminos_condiciones_socio_Infonavit.pdf?MOD=AJPERES" target="_blank">términos y condiciones</a></>} required />
                {formik?.errors?.terms && <ErrorMessage>{formik?.errors?.terms}</ErrorMessage>}
              </div>

              <div className='col-12 d-flex justify-content-center'>
                <Button type="submit" disabled={formik.isSubmitting}>
                  {formik.isSubmitting && <span className="mr-2">Enviando...</span>}
                  {!formik.isSubmitting && "Solicitar alianza"}
                </Button>
              </div>

            </div>
          </Form>
        )}
      </Formik >

      <Modal show={showModal} setShow={setShowModal} container={{ style: modalContainerStyles }}>
        <>
          <img src="/assets/icons/success.svg" alt="success" style={modalImageStyles} />
          <div style={modalTextStyles}>
            <h4>Tus datos han sido enviados</h4>
            <p>¡Gracias por tu interés! Hemos recibido tu solicitud. Nuestro equipo se comunicará contigo en breve para conversar sobre los detalles de nuestra alianza y coordinar los siguientes pasos.</p>
          </div>
          <Button variant='outline' type='button' onClick={() => setShowModal(false)} className='w-100'>Aceptar</Button>
        </>
      </Modal>
    </>
  )
};

export default AlliesForm;
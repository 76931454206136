import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import Home from '../pages/Home';
import Layout from '../components/Layout';
import BenevitsPage from '../pages/BenevitsPage';
import ShowProfile from '../pages/ShowProfile';
import ProtectedRoute from './protected';
import { BenevitInformation } from '../pages/BenevitInformation';
import NotFound from '../pages/NotFound404';
import Search from '../pages/Search';
import Allies from '../pages/Allies';
import Brands from '../pages/Brands';
import FlashPromotions from '../pages/FlashPromotions';

export const Router = () => {
	const [searchParams] = useSearchParams();

	return (
		<Layout>
			<Routes>
				{/* <Route path="/" element={<Navigate to="/" />} /> */}
				<Route path="/" element={<Home />} />
				<Route path="*" element={<ProtectedRoute component={NotFound} />} />
				<Route
					path="/mis-benevits"
					element={<ProtectedRoute component={BenevitsPage} />}
				/>
				<Route
					path="/mi-perfil"
					element={<ProtectedRoute component={ShowProfile} />}
				/>
				<Route
					path="/info-benevit/:id"
					element={<ProtectedRoute component={BenevitInformation} />}
				/>
				<Route
					path={`/resultados`}
					element={<Search />}
				/>
				<Route
					path={`/quieres-ser-aliado`}
					element={<Allies />}
				/>
				<Route
					path={`/marcas`}
					element={<Brands />}
				/>
				<Route
					path={`promociones-relampago`}
					element={<FlashPromotions />}
				/>
			</Routes>
		</Layout>
	);
};

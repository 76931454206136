import AlliesForm from '../../components/AlliesForm/AlliesForm';
import { Container, Title, Description } from './styles';

const Allies = () => {
    return (
      <Container className='container'>
        <div className='row py-4'>
				  <div className='col-12'>
            <Title className="m-0">¿Quieres ser aliado?</Title>
            <Description>Llega a más clientes con Socio Infonavit y ofrece descuentos, ofertas y promociones a millones de derechohabientes.</Description>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <AlliesForm />
          </div>
        </div>
      </Container>
    )
};

export default Allies;
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.module.scss';
import { useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();

  let currentPath = '';

  const crumbs = location.pathname.split('/')
    .filter(crumb => crumb !== '')
    .map((crumb, index) => {
      currentPath += `/${crumb}`;

      if (crumb !== "info-benevit") return (
        <div key={crumb} className={styles.crumb} >
          <Link
            to={currentPath}
            className={index === (location.pathname.split('/').length - 2) ? styles.activeCrumbLink : styles.crumbLink}
          >
            {crumb ? crumb : 'Inicio'}
            {index < (location.pathname.split('/').length - 2) && (
              <img src="/assets/icons/angle-right.svg" alt="arrow" />
            )}
          </Link>
        </div>
      );
    });


  return (
    <div className={styles.breadcrumbs}>
      <div className={styles.crumb} >
        <Link to="/" className={styles.crumbLink}>Inicio</Link>
        <img src="/assets/icons/angle-right.svg" alt="arrow" />
      </div>
      {crumbs}
    </div>
  );
};

export default Breadcrumbs;
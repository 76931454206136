import { initializeApp } from "firebase/app";
import {getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBuhI8YuF4-dKGm9EB8jHXrFoYuTt9i778",
  authDomain: "socio-infonavit-15aa7.firebaseapp.com",
  projectId: "socio-infonavit-15aa7",
  storageBucket: "socio-infonavit-15aa7.appspot.com",
  messagingSenderId: "219476321912",
  appId: "1:219476321912:web:58afebfa3718a8706def8d",
  measurementId: "G-7ZK0N6LXC3"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const messaging = getMessaging(app);
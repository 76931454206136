import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// backgroundDiv: {
		// 	width: '100%',
		// 	height: '100%',
		// 	backgroundColor: 'rgba(0,0,0,0.5)',
		// 	position: 'fixed',
		// 	top: '0',
		// 	left: '0',
		// 	zIndex: '10',
		// 	display: 'flex',
		// 	'&.MuiDialog-root': {
		// 		borderRadius: '22px !important',
		// 	},
		// },

		container: {
			opacity: '1',
			position: 'relative',
			backgroundColor: '#FFFFFF',
			borderRadius: '22px ',
			width: '512px',
			height: 'auto',
			margin: 'auto',
			justifyContent: 'center',
			alignItems: 'center',

			[theme.breakpoints.down('md')]: {
				height: 'auto',
				width: '530px',
				padding: 20,
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				maxWidth: '390px',
				height: 'auto',
				padding: 0,
				paddingBottom: 10,
			},
		},
		closeButton: {
			position: 'absolute',
			width: '22px',
			height: '22px',
			top: '10px',
			right: '8px',
			cursor: 'pointer',
		},
		div: {
			// outline: '2px solid blue',
			// padding: '20px',
			height: '98%',
			width: '85%',
			margin: '10px auto',
			textAlign: 'center',
			[theme.breakpoints.down('md')]: {
				width: '90%',
				// padding: '15px',
				margin: '5px auto',
			},
			[theme.breakpoints.down('sm')]: {
				width: '90%',
				margin: '10px auto',
			},
		},
		divText: {
			// outline: '2px solid green',
			width: '100%',
			height: '45%',
			marginBottom: '10px',
			padding: '0px',
			fontFamily: 'Montserrat',
			[theme.breakpoints.down('md')]: {
				height: '54%',
				marginBottom: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				height: '48%',
			},
		},
		title: {
			fontSize: '25px',
			fontWeight: '900',
			margin: '0',
			paddingTop: '20px',
			[theme.breakpoints.down('md')]: {
				fontSize: '20px',
				paddingTop: '0px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '14px',
				paddingTop: '10px',
			},
		},
		subTitle: {
			fontSize: '15px',
			fontWeight: '900',
			[theme.breakpoints.down('md')]: {
				fontSize: '10px',
			},
		},
		text: {
			color: '#414141',
			fontSize: '15px',

			[theme.breakpoints.down('md')]: {
				fontSize: '13px',
				margin: '8px 0px 8px 0px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '11px',
				
			},
		},
		containerInput: {
			height: 'auto',
			//display: 'flex',
			//flexDirection: 'column',
			//justifyContent: 'space-between',

			[theme.breakpoints.down('md')]: {
				//flexDirection: 'row',
				width: '100%',
				gap: 10,
			},
			[theme.breakpoints.down('sm')]: {
				//flexDirection: 'column',
				gap: 0,
			},
		},
		divInput: {
			marginBottom: '10px',
			outline: '1px solid #EEEEEE',
			height: '54px',
			borderRadius: '14px',
			border: 'none',
			width: '100%',
			// display: 'flex',
			// flexDirection: 'column',
			position: 'relative',
			overflow: 'hidden',
			[theme.breakpoints.down('md')]: {
				marginTop: '5px',
				width: '48%',
				height: '41px',
				borderRadius: '9px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				height: '41px',
			},
		},
		input: {
			// outline: '2px solid black',
			borderRadius: '14px',
			// padding: '20px',
			width: '100%',
			height: '100%',
			color: '#080808',
			paddingLeft: '10px',
			fontSize: '16px',
			outline: 'none',
			background: '#F8F8F8',
			border: 'none',
			'& .MuiOutlinedInput-root': {
				borderRadius: '14px',
				'& .focus-visible': {
					outline: '1px solid #EEEEEE',
					background: '#FFFFFF',
					border: 'none',
				},
				'& fieldset': {
					border: 'none',
				},
				'&:hover fieldset': {
					border: 'none',
				},
				'&.Mui-focused fieldset': {
					border: 'none',
				},
			},
			[theme.breakpoints.down('sm')]: {
				'& .MuiInputLabel-root': {
					fontSize: '0.9rem',
				}
			},
			// '&:focus + label span, &:valid + label span': {
			// 	transform: 'translateY(-18px)',
			// 	fontSize: '14px',
			// },
			// '&:focus ': {
			// 	background: '#FFFFFF',
			// },
			// '&:valid': {
			// 	background: '#FFFFFF',
			// },
			// [theme.breakpoints.down('md')]: {
			// 	borderRadius: '9px',
			// 	fontSize: '12px',
			// 	width: '90%',
			// 	'&:focus + label span, &:valid + label span': {
			// 		transform: 'translateY(-15px)',
			// 		fontSize: '7px',
			// 	},
			// },
			// [theme.breakpoints.down('sm')]: {
			// 	width: '100%',
			// 	'&:focus + label span, &:valid + label span': {
			// 		transform: 'translateY(-13px)',
			// 	},
			// },
		},
		// label: {
		// 	fontSize: '18px',
		// 	position: 'absolute',
		// 	bottom: '12px',
		// 	left: '10px',
		// 	width: '100%',
		// 	height: '100%',
		// 	pointerEvents: 'none',
		// 	color: '#080808',
		// 	[theme.breakpoints.down('md')]: {
		// 		fontSize: '14px',
		// 		bottom: '8px',
		// 	},
		// 	[theme.breakpoints.down('sm')]: {
		// 		bottom: '6px',
		// 	},
		// },
		// name: {
		// 	position: 'absolute',
		// 	bottom: '5px',
		// 	left: '0px',
		// 	transition: 'all 0.3s ease',
		// 	color: '#989898',
		// },

		passwordLost: {
			textAlign: 'right',
			fontSize: '14px',
			color: '#2D9EFF',
			marginTop: '0px',
			[theme.breakpoints.down('md')]: {
				fontSize: '12px',
				textAlign: 'right',
				marginTop: '14px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '11px',
				textAlign: 'right',
				marginTop: '0px',
			},
		},
		register: {
			fontSize: '14px',
			color: '#2D9EFF',
			marginTop: '0px',
			[theme.breakpoints.down('md')]: {
				fontSize: '12px',
				textAlign: 'center',
				marginTop: '14px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '11px',
				textAlign: 'center',
				marginTop: '0px',
			},
		},
		aviso: {
			color: '#8E8E8E',
			fontSize: '15px',
			textDecorationColor: '#8E8E8E',
			[theme.breakpoints.down('md')]: {
				fontSize: '10px',
			},
		},
		divButton: {
			// outline: '2px solid blue',
			//display: 'flex',
			//flexDirection: 'column',
			[theme.breakpoints.down('md')]: {
				//flexDirection: 'row-reverse',
				gap: '10px',
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: '15px',
				//flexDirection: 'column',
				height: '90px',
			},
		},

		button: {
			fontFamily: 'Montserrat',
			background: '#E61737',
			borderRadius: '14px',
			width: '100%',
			textTransform: 'none',
			margin: '10px 0px',
			height: '54px',
			border: 'none',
			fontSize: '18px',
			color: 'white',
			cursor: 'pointer',
			[theme.breakpoints.down('md')]: {
				height: '41px',
				fontSize: '16px',
				width: '50%',
				borderRadius: '9px',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				fontSize: '16px',
				margin: '0px',
				padding: '5px'
			},
		},
		btnRegister: {
			background: '#FFFFFF',
			outline: '1px solid #DC052B',
			color: '#DC052B',
		},
	})
);

import { useEffect, useState } from 'react';
import styles from './Drawer.module.scss';
import { MENU_OPTIONS } from '../../../utils/menuOptions';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../../store/modules/session';
import { RootState } from '../../../store';
import { categoriesAPI } from '../../../api/modules/categories';
import { NavLink, useNavigate } from 'react-router-dom';
import Button from '../../Button';
import userIcon from '../../../assets/img/user-icon.png';

const Drawer = ({ open, setOpen, setOpenRegister, setOpenLogin, setModalNewsLetter, ...props }: any) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.session);
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<any>(null);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const handleClick = (e: any) => {
    setOpenDropdown(false);
    setOpen(!open);
  };

  const handleDropdownClick = (e: any) => {
    e.preventDefault();
    setOpenDropdown(!openDropdown);
  };

  const _renderItems = (options: any) => options.map((option) => {

    if (option.private && !user) return null;

    return (
      <li key={option.id} className={styles.item}>
        <NavLink
          to={option.url || `/resultados?categories=${option.id}`}
          className={styles.link}
          onClick={option.type === 'dropdown' ? handleDropdownClick : handleClick}
        >
          <span className={styles.text}>{option.name}</span>

          {(option.type === 'dropdown') && (
            <img
              src='/assets/icons/angle-down.svg'
              alt='Angle down'
              className={`${styles.angleDown} ${openDropdown ? styles.angleDownOpen : ''}`}
            />
          )}

          {(option.icon || option.icon_url) && (
            <img src={option.icon || option.icon_url} alt={option.name} className={styles.icon} />
          )}
        </NavLink>

        {option.type === 'dropdown' && (
          <ul className={`${styles.dropdown} ${openDropdown ? styles.dropdownOpen : ''}`}>
            {categories && _renderItems(categories)}
          </ul>
        )}
      </li>
    );
  });


  const _renderMenu = (options: any) => (
    <nav className={styles.nav}>
      <ul className={styles.list}>
        {_renderItems(options)}
      </ul>
    </nav>
  );

  const fetchCategories = async () => {
    const { data } = await categoriesAPI.categoriesFiltro();
    console.log(data);
    setCategories(data);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className={`${styles.drawer} ${open ? styles.drawerOpen : ''}`} {...props}>
      <button type='button' className={styles.close} onClick={handleClick} />

      <div className={styles.header}>
        <div className={styles.profile}>
          <img className={styles.avatarImg} src={user?.avatar || userIcon} alt="Profile" />
          Bienvenido
          {user && <span className={styles.userName}>{user?.name}</span>}
        </div>

        {_renderMenu(MENU_OPTIONS.header)}

        {user && (
          <button
            type='button'
            className={styles.link}
            onClick={(e) => {
              setModalNewsLetter(true);
              setOpen(false)
            }}
          >
            <span className={styles.text}>Preferencias Newsletter</span>
          </button>
        )}
      </div>

      <div className={styles.body}>
        {_renderMenu(MENU_OPTIONS.body)}

        <NavLink
          to={`/quieres-ser-aliado`}
          className={styles.link}
          onClick={handleClick}
        >
          <span className={styles.text}>¿Quieres ser aliado?</span>
        </NavLink>

        {user && (
          <button
            type='button'
            className={styles.link}
            onClick={(e) => {
              dispatch(actions.signOut()); navigate('/');
              setOpen(false);
            }}
          >
            <img
              src='/assets/icons/logout.svg'
              alt='Logout'
              className={styles.icon}
            />
            <span className={styles.text}>Cerrar sesión</span>
          </button>
        )}

        {!user && (
          <Button
            type='button'
            className="w-100"
            onClick={(e) => {
              setOpenLogin(true);
              setOpen(false);
            }}
          >
            Ingresar
          </Button>
        )}
      </div>

    </div >
  );
};

export default Drawer;
import styled from '@emotion/styled';

export const Container = styled.div`
  max-width: 660px;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 700;
`;

export const Description = styled.p`
  font-size: 20px;
  font-weight: 400;
`;
import {
	INITIAL_STATE,
	SET_USER,
	UPDATE_USER,
	SIGN_OUT,
	SIGN_IN,
	CHANGE_REMEMBER,
	IS_OPEN_NEWSLETTER,
	SesionState,
	SignInProps,
} from '../types/session';
import { createAsyncThunk, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../interfaces/user';
import userEvent from '@testing-library/user-event';

export const actions = {
	setUser: createAsyncThunk(SET_USER, (user: IUser) => {
		// localStorage.setItem('tk', user.token);
		return user;
	}),
	updateUser: createAsyncThunk(UPDATE_USER, (user: IUser) => {
		// localStorage.setItem('tk', user.token);
		return user;
	}),
	signIn: createAsyncThunk(SIGN_IN, (props: SignInProps) => {}),
	signOut: createAsyncThunk(SIGN_OUT, () => {
		// localStorage.clear();
		return Promise.resolve();
	}),
	changeRemember: createAsyncThunk(CHANGE_REMEMBER, (value: number) => {
		return value;
	}),
	setIsOpenNewsletter: createAsyncThunk(IS_OPEN_NEWSLETTER, () => {}),
	setNotOpenNewsletter: createAsyncThunk(IS_OPEN_NEWSLETTER, () => {}),
};

export default createReducer(INITIAL_STATE, {
	[actions.setUser.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => ({
		user: action.payload,
		isLogged: true,
		isOpenNewsletterModal:  false ,
	}),
	[actions.updateUser.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => ({
		user: action.payload,
		isLogged: true,
		isOpenNewsletterModal:  true ,
	}),
	[actions.signIn.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => {
		return {
			user: action.payload,
			isLogged: true,
			isOpenNewsletterModal: true,
		};
	},
	[actions.setIsOpenNewsletter.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => {
		return {
			..._state,
			isOpenNewsletterModal: true,
		};
	},
	[actions.setNotOpenNewsletter.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<IUser>
	): SesionState => {
		return {
			..._state,
			isOpenNewsletterModal: false,
		};
	},
	[actions.changeRemember.fulfilled.type]: (
		_state: SesionState,
		action: PayloadAction<number>
	): SesionState => {
		return {
			..._state,
		};
	},
	[actions.signIn.rejected.type]: (state: SesionState): SesionState => {
		return state;
	},
	[actions.signOut.fulfilled.type]: () => ({
		user: null,
		isLogged: false,
		isOpenNewsletterModal: false,
	}),
});

export default[
  {
    id: "welcome",
    title: 'Bienvenid@',
    text: 'Aquí puedes buscar por nombre, servicio o producto',
    attachTo: { element: '[data-tut="reactour__iso"]', on: "top" as const},
    classes: "shepherd",
    scrollTo: true,
    scrollToHandler: function(e) {
      window.scrollTo({
        top: 400,
        behavior: 'smooth',
      });
    },
    buttons: [
      {
        type: "cancel",
        classes: "shepherd-button-secondary",
        text: "Salir"
      },
      {
        type: "next",
        text: "Siguiente"
      }
    ]
  },
  {
      id: "welcome2",
      title: 'Filtros',
      text: 'Aquí podrás filtrar y encontrar más rápido los productos o servicios que buscas',
      attachTo: { element: '[data-tut="reactour__box"]', on: "top" as const},
      classes: "shepherd",
      scrollTo: true,
      scrollToHandler: function(e) {
        window.scrollTo({
          top: 400,
          behavior: 'smooth',
        });
      },
      buttons: [
        {
          type: "cancel",
          classes: "shepherd-button-secondary",
          text: "Salir"
        },
        {
          type: "next",
          text: "Siguiente"
        }
      ]
  },
  {
      id: "welcome3",
      title: 'Mis Benevits',
      text: 'Aquí encontrarás tus benevits que has descubierto',
      attachTo: { element: '[data-tut="reactour__box2"]', on: "bottom" as const},
      classes: "shepherd",
      scrollTo: true,
      scrollToHandler: function(e) {
        window.scrollTo({
          top: 400,
          behavior: 'smooth',
        });
      },
      buttons: [
        {
          type: "cancel",
          classes: "shepherd-button-secondary",
          text: "Salir"
        },
        {
          type: "next",
          text: "Siguiente"
        }
      ]
    },
    {
      id: "welcome4",
      title: 'Mi Perfil',
      text: 'Aquí puedes configurar tus preferencias de perfil',
      attachTo: { element: '[data-tut="reactour__box3"]', on: "bottom" as const},
      classes: "shepherd",
      scrollTo: true,
      scrollToHandler: function(e) {
        window.scrollTo({
          top: 400,
          behavior: 'smooth',
        });
      },
      buttons: [
        {
          type: "cancel",
          classes: "shepherd-button-secondary",
          text: "Salir"
        },
        {
          type: "next",
          text: "Siguiente"
        }
      ]
    },
    {
      id: "welcome5",
      title: 'Descubre un Benevit',
      text: 'Para descubrir un benevit, da clic en "Lo quiero"',
      attachTo: { element: '[data-tut="reactour__box4"]', on: "bottom" as const},
      classes: "shepherd",
      scrollTo: true,
      scrollToHandler: function(e) {
        window.scrollTo({
          top: 800,
          behavior: 'smooth',
        });
      },
      buttons: [
        {
          type: "cancel",
          classes: "shepherd-button-secondary",
          text: "Salir"
        },
        {
          type: "complete",
          text: "Terminar"
        }
      ]
    }
];
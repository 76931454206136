import { useState, useLayoutEffect, useEffect } from 'react';
import { textToCapitalLetter } from '../../utils/expressions';
import { useNavigate, NavLink } from 'react-router-dom';
import userIcon from '../../assets/img/user-icon.png';
import { RootState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import Login from '../Login/Login';
import Register from '../Register/Register';
import NewsletterModify from '../Newsletter/NewsletterModify';
import styles from './NavBar.module.scss';
import { categoriesAPI } from '../../api/modules/categories';
import Button from '../Button';

import {
	Container,
	Nav,
	NavItem,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Input,
	InputGroup
} from 'reactstrap';
import MobileMenu from '../MobileMenu/MobileMenu';

type categoriesFiltro = {
	id: number;
	name: string;
	icon_url: string;
};

const Navbar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user } = useSelector((state: RootState) => state.session);
	const [open, setOpen] = useState<boolean>(false);
	const [openLogin, setOpenLogin] = useState<boolean>(false);
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [modalNewsLetter, setModalNewsLetter] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState(false);
	const [selectCatAList, setSelectedCatList] = useState<Array<categoriesFiltro>>([]);
	const [query, setQuery] = useState<string>('');
	const [isMobile, setIsMobile] = useState(false);


	const categoriesFunction = async () => {
		try {
			const res = await categoriesAPI.categoriesFiltro();
			const categories = res?.data;
			setSelectedCatList(categories);
		} catch (error) {

		}

	};

	const toggle = () => setIsOpen(!isOpen);

	const isOpenDrawer = () => {
		setModalNewsLetter(false);
		setOpen(!open);
	};

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	};

	const handleSearchClick = () => {
		navigate(`/resultados${query.length ? `?query=${query}` : ''}`);
	};

	const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			navigate(`/resultados${query.length ? `?query=${query}` : ''}`);
		}
	};

	const handleResize = () => {
		if (window.innerWidth < 1200) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useLayoutEffect(() => {
		function updateSize() {
			setOpen(false);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	useEffect(() => {
		categoriesFunction();
		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<>
			{isMobile && <MobileMenu setModalNewsLetter={setModalNewsLetter} setOpenLogin={setOpenLogin} setOpenRegister={setOpenRegister} />}

			{!isMobile && (
				<nav className={styles.navbar}>
					{/* Top menu */}
					<div className={styles.navbarRow}>
						<Container fluid={false}>
							<div className={styles.topMenu}>
								<div className={styles.topMenuLeft}>
									<NavLink to="/">
										<img
											className={`img-fluid ${styles.logo}`}
											src="/assets/brand/socio-infonavit-color.svg"
											alt="infonavit"
										/>
									</NavLink>

									{/* Searcher */}
									<InputGroup className={styles.search}>
										<Input onChange={handleSearchChange} onKeyDown={handleEnter} />
										<Button className={styles.buttonSearch} onClick={handleSearchClick}>
											Buscar
										</Button>
									</InputGroup>
								</div>
								<div className={styles.topMenuRight}>
									{/* If user is logged */}
									{user && (
										<div className={styles.userInfoContainer}>
											<div className={styles.userInfo}>
												<div className={styles.userInfoWelcome}>
													Bienvenido <span className={styles.userName}>{user.name ? textToCapitalLetter(user.name) : ''}</span>
												</div>

												<UncontrolledDropdown>
													<DropdownToggle nav caret className={styles.userInfoToggle}>
														Cuenta
													</DropdownToggle>
													<DropdownMenu end className={styles.userInfoDropdown}>
														<DropdownItem href="/mi-perfil" className={styles.option}>Mi perfil</DropdownItem>
														<DropdownItem onClick={() => { setModalNewsLetter(true) }} className={styles.option}>Preferencias Newsletter{' '}</DropdownItem>
														<DropdownItem onClick={() => { dispatch(actions.signOut()); navigate('/'); }} className={styles.option}>Cerrar Sesión</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											</div>
											<div>
												<img className={styles.avatar} src={user.avatar || userIcon} alt="" />
												{/*<UserInfo
													user={user}
													setModalNewsLetter={setModalNewsLetter}
												/>*/}
											</div>
										</div>
									)}

									{!user && (
										<Button variant='solid' onClick={() => { setOpenLogin(!openLogin) }}>
											Ingresar
										</Button>
									)}
								</div>
							</div>
						</Container>
					</div >

					{/* Bottom menu */}
					<div className={styles.navbarRow}>

						<Container>
							<div className={styles.bottomMenu}>
								<Nav className={styles.menu} navbar>
									<NavItem>
										<NavLink to="/" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill="#DD0528" className="bi bi-house-door-fill" viewBox="0 0 16 16">
												<path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 
											.5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 
											0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"/>
											</svg>
											Inicio
										</NavLink>
									</NavItem>
									<UncontrolledDropdown nav inNavbar>
										<DropdownToggle nav caret className={styles.menuItem}>
											Categorías
										</DropdownToggle>
										<DropdownMenu end className={styles.dropdown}>
											{selectCatAList.map((option, index) => (
												<DropdownItem key={option.id} href={`/resultados?categories=${option.id}`} className={styles.option}>
													<img className={styles.optionIcon} src={option?.icon_url} alt={option?.name} />
													{option.name}
												</DropdownItem>)
											)}
										</DropdownMenu>
									</UncontrolledDropdown>
									<NavItem>
										<NavLink to="/marcas" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>Marcas</NavLink>
									</NavItem>
									<NavItem>
										<NavLink to="/promociones-relampago" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>Promociones relámpago</NavLink>
									</NavItem>
									{user && (
										<NavItem>
											<NavLink to="/mis-benevits" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>Mis benevits</NavLink>
										</NavItem>
									)}

									<NavItem className={styles.itemRight}>
										<NavLink to="/quieres-ser-aliado" className={({ isActive }) => (isActive ? `${styles.menuItemActive} active` : styles.menuItem)}>¿Quieres ser aliado?</NavLink>
									</NavItem>
								</Nav>
							</div>
						</Container>
					</div>
				</nav >
			)}

			{openLogin && (
				<Login
					email=""
					openLogin={openLogin}
					setOpenLogin={setOpenLogin}
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
				/>
			)}

			{openRegister && (
				<Register
					email=""
					openRegister={openRegister}
					setOpenRegister={setOpenRegister}
					openLogin={openLogin}
					setOpenLogin={setOpenLogin}
				/>
			)}

			{modalNewsLetter && <NewsletterModify modalNewsLetter={modalNewsLetter} setModalNewsLetter={setModalNewsLetter} />}
		</>
	);
};

export default Navbar;

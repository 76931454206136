import Slider from 'react-slick';
import { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { carouselAPI } from '../../api/modules/carousel';
import Login from '../Login/Login';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import prev from '../../assets/img/buttom/prevA.png';
import next from '../../assets/img/buttom/nextA.png';

type marcas = {
	marcas: object[];
};

type props = {
	setIdBrand: (arg: number | string) => void;
};

const NextArrow = (props: any) => {
	const { className, onClick } = props;
	return (
		<img
			alt="logo"
			className={className}
			src={next}
			onClick={onClick}
			style={{
				width: '26px',
				height: '26px',
				display: 'block',
				background: 'white',
				boxShadow: ' 0px 17px 27px #9D636317',
				borderRadius: '50%',
				padding: '3px',
				marginRight: '-10px',
			}}
		/>
	);
};

const PrevArrow = (props: any) => {
	const { className, style, onClick } = props;
	return (
		<img
			alt="logo"
			className={className}
			src={prev}
			onClick={onClick}
			style={{
				...style,
				width: '26px',
				height: '26px',
				display: 'block',
				background: 'white',
				boxShadow: ' 0px 17px 27px #9D636317',
				borderRadius: '50%',
				padding: '3px',
				marginLeft: '-10px',
			}}
		/>
	);
};

const settings = {
	className: 'center',
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 6,
	slidesToScroll: 6,
	initialSlide: 0,
	padding: '60px',
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />,
	responsive: [
		{
			breakpoint: 1100,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 5,
				infinite: true,
			},
		},
		{
			breakpoint: 800,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4,
				infinite: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
			},
		},
	],
};
const settingsInicio = {
	className: 'center',
	dots: false,
	infinite: true,
	speed: 500,
	slidesToShow: 6,
	slidesToScroll: 6,
	initialSlide: 0,
	padding: '60px',
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />,
	responsive: [
		{
			breakpoint: 1100,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 5,
				infinite: true,
			},
		},
		{
			breakpoint: 800,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4,
				infinite: true,
			},
		},
		{
			breakpoint: 600,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				infinite: true,
			},
		},
		{
			breakpoint: 400,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
			},
		},
	],
};
const settingsRow = {
	infinite: true,
	slidesToScroll: 3,
	slidesToShow: 3,
	slidesPerRow: 1,
	rows: 2,
	speed: 500,
	nextArrow: <NextArrow />,
	prevArrow: <PrevArrow />,
	responsive: [
		{
			breakpoint: 400,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
				infinite: true,
				slidesPerRow: 1,
				rows: 2,
				speed: 500,
			},
		},
	],
};

const CarouselMarcas = ({ setIdBrand }: props) => {
	const classes = useStyles();
	const { user } = useSelector((state: RootState) => state.session);
	const [carouselUser, setCarouselUser] = useState<Array<marcas>>([]);
	const [openLogin, setOpenLogin] = useState<boolean>(false);
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [selectedBrand, setSelectedBrand] = useState<number>();

	const carouselFunction = async () => {
		const res = await carouselAPI.carouselMarcas();
		const marcas = res.data;
		const activeMarcas = marcas.filter((marca: any) => marca.active === true);
		setCarouselUser(activeMarcas.sort(() => Math.random() - 0.5));
	};

	useEffect(() => {
		carouselFunction();
	}, [user]);

	let allMarcas: any = carouselUser;
	let limitNumberMarcas: any = allMarcas.slice(0, 18);

	const handleIdBrand = (id: number) => {
		setSelectedBrand(id);
		setIdBrand(id);
	};

	return (
		<>
			{user !== null ? (
				<>
					<p className={`${classes.title} ${classes.titleInicio}`}>
						Encuentra tu marca favorita
					</p>
					<div className={classes.containerCarouselInicio}>
						<Slider {...settingsInicio}>
							{allMarcas.map((marca: any) => {
								return (
									<div
										onClick={() => handleIdBrand(marca.id)}
										className={`${
											selectedBrand === marca.id
												? classes.selected
												: classes.boxInicio
										}  `}
										key={marca.id}
									>
										<img
											alt="logo"
											src={marca.mini_logo_full_path}
											className={`${classes.imgMarcaInicio}`}
										/>
									</div>
								);
							})}
						</Slider>
					</div>
				</>
			) : (
				<>
					<p className={classes.title}>Algunas de nuestras marcas</p>
					<div className={classes.containerCarousel}>
						<Slider {...settings}>
							{limitNumberMarcas.map((marca: any) => {
								return (
									<div
										key={marca.id}
										className={`${classes.box}`}
										onClick={() => setOpenLogin(!openLogin)}
										style={{ cursor: 'pointer' }}
									>
										<img
											alt="logo"
											style={{ cursor: 'pointer' }}
											className={classes.imgMarca}
											src={marca.mini_logo_full_path}
										/>
									</div>
								);
							})}
						</Slider>
					</div>

					{/*openLogin && (
						<Login
							email=""
							openLogin={openLogin}
							setOpenLogin={setOpenLogin}
							openRegister={openRegister}
							setOpenRegister={setOpenRegister}
						/>
					)*/}

					<div className={classes.containerCarouselMobile}>
						<Slider {...settingsRow}>
							{limitNumberMarcas.map((marca: any) => {
								return (
									<Box key={marca.id} className={classes.box}>
										<img
											alt="logo"
											className={classes.imgMarca}
											src={marca.mini_logo_full_path}
										/>
									</Box>
								);
							})}
						</Slider>
					</div>
				</>
			)}
		</>
	);
};

export default CarouselMarcas;

import React, { useEffect, useState } from "react";
import styles from "./MaterialField.module.scss";
import { ErrorMessage, Field, useField } from "formik";

const MaterialField = ({
  name,
  type,
  label,
  placeholder,
  tooltip,
  helper,
  className,
  children,
  validate,
  required,
  ...props
}: any) => {
  const [field, meta] = useField(name);
  const [isFilled, setFilled] = useState(false);

  // Toggle the filled state when the field meta changes
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (meta.value) {
        setFilled(meta.value.length > 0);
      }
    }
  }, [meta, props]);

  return (
    <div
      className={`${styles.fieldGroup} ${className}  ${props.as === "select" && styles.select}`}
    >
      <div className={`${styles.fieldContainer} ${isFilled ? styles.filled : ''}`}>
        {/* Field */}
        <Field
          className={`${styles.field} ${type === "date" && styles.dateField} ${type === "file" && styles.fileField
            } ${meta.touched && meta.error && styles.error}`}
          name={name}
          id={name}
          type={type}
          placeholder={placeholder}
          {...props}
        >
          {children && props.as === "select" && children}
        </Field>

        {/* Label */}
        {label && (
          <label
            className={`${styles.label} ${props.disabled ? 'disabled' : ''} ${meta.touched && meta.error && styles.error
              }`}
            htmlFor={name}
          >
            {label}

            {/* Required */}
            {required && <span className={styles.required}>*</span>}

            {/* Tootip */}
            {tooltip && (
              <div className={styles.tooltip}>
                <img
                  src="/assets/icons/landing/form/info-icon.svg"
                  width={20}
                  height={20}
                  alt="info"
                />
                <span dangerouslySetInnerHTML={{ __html: tooltip }} />
              </div>
            )}
          </label>
        )}

        {helper && <small className={styles.helper}>{helper}</small>}
      </div>

      {/* Errormessage */}
      {meta.touched && meta.error && validate !== false && (
        <div className={styles.errorMessage}>
          <ErrorMessage name={name} />
        </div>
      )}
    </div>
  );
};

export default MaterialField;

import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import backImg from '../../assets/img/BGopc02.png';

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		divWelcome: {
			width: '100%',
			height: 'auto',
			backgroundImage: `url(${backImg})`,
			backgroundRepeat: 'no-repeat',
			background: '#F4F4F4',
			backgroundSize: '100%',
			textAlign: 'center',
			[theme.breakpoints.down('md')]: {
				backgroundImage: 'none',
			}
		},
		title: {
			letterSpacing: '-1px',
			fontSize: '55px',
			fontWeight: 800,
			margin: '0px auto',
			padding: '80px 0px 56px 0px',
			height: 'auto',
			[theme.breakpoints.down('md')]: {
				padding: '85px 0px 23px 0px',
				fontSize: '20px',
			},
			[theme.breakpoints.down('sm')]: {
				padding: '30px 0px 23px 0px',
			},
		},
		text: {
			width: '50%',
			fontSize: '18px',
			color: '#414141',
			margin: '0px auto',
			paddingBottom: '15px',
			[theme.breakpoints.down('md')]: {
				fontSize: '12px',
				width: '70%',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '12px',
				width: '80%',
			},
		},
	})
);

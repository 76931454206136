import React from 'react';
import { useStyles } from './styles';

export type TextProps = {
	margin?: string | number;
	// Children stands for text
	children?: string | JSX.Element | JSX.Element[];
	// Font props
	fontFamily?: string;
	fontSize?: string | number;
	fontWeight?: string | number;
	marginBottom?: string | number;
	width?: string | number;
	color?: string;
	textAlign?:
		| 'start'
		| 'end'
		| 'left'
		| 'right'
		| 'center'
		| 'justify'
		| 'match-parent';
};

const Text = ({
	margin = 0,
	children,
	fontFamily = 'Avenir',
	fontSize = '16px',
	fontWeight = 'normal',
	marginBottom = 'auto',
	width = 'auto',
	color = '#000000',
	textAlign = 'left',
}: TextProps) => {
	const classes = useStyles({
		margin,
		fontFamily,
		fontSize,
		fontWeight,
		marginBottom,
		width,
		color,
		textAlign,
	});
	return <p className={classes.text}>{children}</p>;
};

export default Text;

import { Button, Modal, Paper, Box } from '@mui/material';
import { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import Text from '../Text/Text';
import VideoControlButton from './components/VideoControlButton/VideoControlButton';
import { useStyles } from './styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import MoreInformationModal from './components/MoreInformationModal/MoreInformationModal';

type Props = {
	open: boolean;
	onCancel: () => void;
	handleUnlockBenevit: (answers?: number[]) => void;
	url: any;
	campaingURL: string;
	isMobileVideo?: boolean;
	light: boolean;
	// onMoreInfoClick: () => void;
};

const VideoPlaybackModal = ({
	open,
	onCancel,
	url,
	handleUnlockBenevit,
	campaingURL,
	isMobileVideo,
	light
}: // onMoreInfoClick,
Props) => {
	const classes = useStyles();
	const videoPlayerRef = useRef<ReactPlayer>(null);
	const [videoDidFinish, setVideoDidFinish] = useState<boolean>(false);
	const [urlVideo, setUrlVideo] = useState<string>('');
	const [mobileContainer, setMobileContainer] = useState<boolean>(false);
	const [modalContainer, setModalContainer] = useState<boolean>(false);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handlePlayAgain = (): void => {
		videoPlayerRef.current?.seekTo(0);
		//videoPlayerRef.current?.playing(true)
		setVideoDidFinish(false);
	};

	const handleMoreInfo = (): void => {
		// onMoreInfoClick();
	};

	const handleUnlock = (): void => {};
	useEffect(() => {
		if (isMobileVideo) {
			if (url?.content_mobile) {
				setModalContainer(false);
				setMobileContainer(true);
				setUrlVideo(url?.content_mobile);
			} else {
				setMobileContainer(false);
				setModalContainer(true);
				setUrlVideo(url?.content);
			}
		} else {
			setModalContainer(false);
			setMobileContainer(false);
			setUrlVideo(url?.content);
		}
	}, []);

	return (
		<>
			<Modal open={open} hideBackdrop className={classes.modalRoot}>
				<Paper
					className={`${
						modalContainer
							? classes.mobileModalContainer
							: classes.modalContainer
					}`}
				>
					<Box
						className={`${
							mobileContainer
								? classes.mobileVideoContainer
								: classes.videoContainer
						}`}
					>
						{!videoDidFinish ? (
							<ReactPlayer
								ref={videoPlayerRef}
								url={urlVideo}
								onEnded={() => setVideoDidFinish(true)}
								width="65vw"
								height="65vh"
								style={{ display: !videoDidFinish ? 'block' : 'none' }}
								playing={!videoDidFinish}
								controls
								light={light}
								//playIcon={<img src="https://picsum.photos/200/300"/>}
							/>
						) : (
							<Box
								className={classes.playerButtonsContainer}
								style={{ display: videoDidFinish ? 'flex' : 'none' }}
							>
								<VideoControlButton
									buttonProps={{
										onClick: handlePlayAgain,
									}}
								>
									VOLVER A REPRODUCIR
								</VideoControlButton>
								<VideoControlButton
									buttonProps={{
										onClick: () => window.open(campaingURL, '_blank'),
									}}
								>
									MÁS INFORMACIÓN
								</VideoControlButton>
								<VideoControlButton
									buttonProps={{
										onClick: () => handleUnlockBenevit(),
									}}
								>
									DESBLOQUEAR
								</VideoControlButton>
							</Box>
						)}
					</Box>
					<Box className={classes.cancelButtonContainer}>
						<Button onClick={onCancel} style={{ textTransform: 'none' }}>
							<Text
								color="#e61737"
								fontSize={!isMobile ? 20 : 16}
								textAlign="center"
							>
								Cancelar
							</Text>
						</Button>
					</Box>
				</Paper>
			</Modal>
		</>
	);
};

export default VideoPlaybackModal;

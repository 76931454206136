import { createTheme } from '@mui/material/styles';

const theme = createTheme({
	typography: {
		fontFamily: [
			'Montserrat',
			'Poppins',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		h1: {
			fontFamily: 'Poppins',
		},
		h2: {
			fontFamily: 'Poppins',
		},
		h3: {
			fontFamily: 'Poppins',
		},
		h4: {
			fontFamily: 'Poppins',
		},
		h5: {
			fontFamily: 'Poppins',
		},
		h6: {
			fontFamily: 'Poppins',
		},
	},
	palette: {
		primary: {
			main: '#DD0528',
			dark: '#CE0020',
		},
		secondary: {
			main: '#0126C3',
		},
		info: {
			main: '#71C65D',
		},
		background: {
			default: '#FFF',
		},
	},
});

export default theme;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import moment from 'moment';
import {
  Card,
  Cover,
  CoverImage,
  BrandLogo,
  Content,
  ContentText,
  Title,
  Discount,
  Properties,
  Category,
  CategoryIcon,
  CategoryLabel,
  Availability,
  ExpirationDate,
  Days,
  Actions
} from './styles';
import Button from '../Button';

interface IItemCard {
  handleSetBenevit?: any;
  data: any;
};

const ItemCard = ({ data, handleSetBenevit }: IItemCard) => {
  const { user } = useSelector((state: RootState) => state.session);
  const [isMobile, setIsMobile] = useState(false);
  const exp_days = moment(data.expiration_date).diff(moment(), 'days') + 1;
  const getExpirationDate = (days: number) => <ExpirationDate>Vence en <Days>{days}</Days> {days === 1 ? ' día' : 'días'}</ExpirationDate>;

  const renderCategories = (categories: any) => categories.map((category: any, index: number) => (
    <Category key={index}>
      {category?.icon_url && <CategoryIcon src={category?.icon_url} />}
      <CategoryLabel>{category?.name}</CategoryLabel>
    </Category>
  ));

  const _renderTerritores = (territories: any) => territories.map(
    (territory: any, index: number) => (<Availability key={index}>{territory?.name}</Availability>)
  );

  // On mount
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Card>
      <Cover background={data?.primary_color}>
        <CoverImage src={data?.vector_full_path} alt="random" />
        <BrandLogo src={data?.ally?.mini_logo_full_path} alt="random" />
      </Cover>
      <Content>
        <ContentText>
          {/* Basic information */}
          <Title>{data.title}</Title>
          {data.id === 1000 && <span>Hasta agotar existencias</span>}

          {data?.discount && <Discount>{data?.discount}</Discount>}

          {/* Properties */}
          <Properties>
            {data?.categories && renderCategories(data?.categories)}

            {data?.territories?.length <= 1 && _renderTerritores(data?.territories)}
          </Properties>

          {/* If data is higher than 1 show territorie below */}
          {data?.territories?.length > 1 && (
            <Properties>
              {_renderTerritores(data?.territories)}
            </Properties>)
          }

          {/* Expiration date */}
          {getExpirationDate(exp_days)}
        </ContentText>

        {/* Actions */}
        <Actions>
          <Button onClick={() => handleSetBenevit(data)} variant="solid" fluid={true} state={data?.unlocked ? 'success' : 'default'}>
            {data?.unlocked === true ? data.id === 1000 ? 'Participando' : 'Ver beneficio' : 'Lo quiero'}
          </Button>
        </Actions>
      </Content>
    </Card>);
}

export default ItemCard;
import API from '..';
class EmailVerificationAPI extends API {
	emailVerification(email: string): Promise<any> {
		return this.requestWrapper(
			this.request({
				url: `/v2/member/verify-email`,
				method: 'POST',
				data: {
					email: `${email}`,
				},
				headers: {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
			}),
			true
		);
	}
}
export const emailVerificationAPI = new EmailVerificationAPI('');

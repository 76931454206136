import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Paper, Box, useMediaQuery, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { RootState } from '../../store';
import CircleImg from './CircleImg';
import control from '../../assets/img/control.png';
import lapiz from '../../assets/img/lapiz.svg';
import FullWidthLoader from '../../components/FullWidthLoader';
import { actions } from '../../store/modules/session';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from './UseStyle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
const axios = require('axios').default;

const ShowProfile = () => {
	const { user } = useSelector((state: RootState) => state.session);
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const matchMovil = useMediaQuery(useTheme().breakpoints.down('sm'));
	const [foto, setFoto] = useState<any>('');
	const [loader, setLoader] = useState(false);
	const [errorRequest, setErrorRequest] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const API_URLS = process.env.REACT_APP_API_URL + '/v1/member/members_avatar';

	useEffect(() => {
		setFoto(user?.avatar ? user?.avatar : control);
	}, [user]);

	const chageImage = async (e: any) => {
		const { files } = e.target;
		if (user && files) {
			try {
				setLoader(true);
				setErrorRequest('');
				const headers = {
					headers: {
						Authorization: user?.token,
						'Content-Type': 'multipart/form-data',
					},
				};
				let formData = new FormData();
				formData.set('avatar', files[0]);
				const { data } = await axios.put(
					`${API_URLS}/${user?.id}`,
					formData,
					headers
				);
				if (data.avatar) {
					dispatch(
						actions.setUser({
							...user,
							avatar: data.avatar,
						})
					);
				} else setErrorRequest('Error al actualizar avatar.');
			} catch (error) {
				setErrorRequest('Error al actualizar avatar.');
			} finally {
				setLoader(false);
			}
		}
	};

	return (
		<>
			<div style={{ height: 80 }} />
			<Box
				style={{
					width: '100%',
					padding: matches ? (matchMovil ? '20px 0px' : '80px 0px') : 0,
					height: matches ? '100%' : 'calc(100vh - 80px)',
					position: 'relative',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
				}}
			>
				{!matches && <CircleImg />}

				{matchMovil && (
					<Box
						display={'block'}
						textAlign={'start'}
						width={'100%'}
						paddingLeft={4}
					>
						<CustomButton variant="contained" onClick={() => navigate(-1)}>
							<ArrowBackIosNewIcon
								style={{ fontSize: 12, marginRight: 10, fontWeight: 700 }}
							/>{' '}
							Regresar
						</CustomButton>
					</Box>
				)}

				<Paper
					style={{
						borderRadius: 20,
						marginTop: matchMovil ? '80px' : 0,
						width: matchMovil ? '90%' : '100%',
						maxWidth: matches ? '400px' : '60vh',
						boxShadow: '0px 17px 27px #9D636329',
					}}
				>
					<Box
						position={'relative'}
						paddingTop={matches ? 10 : 12}
						paddingBottom={4}
						textAlign={'center'}
					>
						{errorRequest && <Alert severity="error">{errorRequest}</Alert>}

						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								width: '100%',
								top: matches ? -60 : -75,
								left: 0,
								position: 'absolute',
							}}
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									position: 'relative',
									width: matches ? 130 : 150,
									height: matches ? 130 : 150,
									borderRadius: '50%',
									backgroundImage: 'url(' + foto + ')',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat',
									boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
								}}
							>
								<label>
									<input
										type="file"
										accept="image/*"
										name="file"
										onChange={chageImage}
										style={{ display: 'none' }}
									/>
									<img
										src={lapiz}
										alt="lapiz"
										style={{
											userSelect: 'none',
											cursor: 'pointer',
											position: 'absolute',
											right: 10,
											bottom: 0,
											width: 30,
											height: 30,
										}}
									/>
								</label>
							</div>
						</div>

						<Box marginBottom={4} marginTop={4}>
							<h4 style={{ margin: 0, fontSize: matches ? 16 : '2.5vh' }}>
								{user?.name}
							</h4>
							<p style={{ margin: 0, fontSize: matches ? 16 : '2.5vh' }}>
								Nombre
							</p>
						</Box>
						<Box marginBottom={4}>
							<h4 style={{ margin: 0, fontSize: matches ? 16 : '2.5vh' }}>
								{user?.lastname}
							</h4>
							<p style={{ margin: 0, fontSize: matches ? 16 : '2.5vh' }}>
								Apellidos
							</p>
						</Box>
						<Box>
							<h4 style={{ margin: 0, fontSize: matches ? 16 : '2.5vh' }}>
								{user?.email}
							</h4>
							<p style={{ margin: 0, fontSize: matches ? 16 : '2.5vh' }}>
								Email
							</p>
						</Box>
					</Box>
				</Paper>
			</Box>
			<FullWidthLoader open={loader} />
		</>
	);
};

export default ShowProfile;

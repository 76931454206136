import { useRef, useEffect, useState } from 'react';
import { useStyles } from './styles';
import { TextField, Alert, Link, Dialog } from '@mui/material';
import close from '../../assets/img/buttom/close.svg';
import { emailValid, nssValid, isNumber } from '../../utils/expressions';
import { authAPI } from '../../api/modules/auth';
import { AlertColor } from '@mui/material';
import { useDispatch } from 'react-redux';
import { actions } from '../../store/modules/session';
import { IUser } from '../../interfaces/user';
import JSEncrypt from 'jsencrypt';
import FullWidthLoader from '../FullWidthLoader';
import NoCredential from '../NoCredential';

interface ICredentials {
	email: string;
	password: string;
}

interface IAlert {
	show: boolean;
	message: string;
	severity: AlertColor;
}

interface Props {
	openLogin: boolean;
	setOpenLogin: React.Dispatch<React.SetStateAction<boolean>>;
	openRegister: boolean;
	setOpenRegister: React.Dispatch<React.SetStateAction<boolean>>;
	email?: string;
}

const Login = ({
	openLogin,
	setOpenLogin,
	openRegister,
	setOpenRegister,
	email,
}: Props) => {
	const classes = useStyles();
	const wrapperRef = useRef(null);
	const dispatch = useDispatch();
	const [openCred, setOpenCred] = useState<boolean>(false);

	const [credentials, setCredentials] = useState<ICredentials>({
		email: '',
		password: '',
	});
	const [emailError, setEmailError] = useState({ valid: false, msg: '' });
	const [alert, setAlert] = useState<IAlert>({
		message: '',
		show: false,
		severity: 'success',
	});
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		if (email) {
			let validate = { valid: false, msg: '' };
			// if (isNumber(email)) {
			// 	validate = nssValid(email);
			// } else {
			// 	validate = emailValid(email);
			// }
			validate = nssValid(email);
			setEmailError(validate);
			setCredentials({ ...credentials, email: email });
		}
	}, [email]);

	const setData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'email') {
			let validate = { valid: false, msg: '' };
			const re = /^[0-9\b]+$/;
			if (value === '' || re.test(value)) {
				validate = nssValid(value);
				setEmailError(validate);
			} else {
				return;
			}
		}
		setCredentials({ ...credentials, [name]: value });
	};

	const handleKeypress = (e: any) => {
		//it triggers by pressing the enter key
		if (e.key === 'Enter') {
			onSubmit();
		}
	};

	const onSubmit = async (): Promise<void> => {
		if (!emailError.valid) {
			setAlert({
				show: true,
				message: emailError.msg || 'NSS inválida',
				severity: 'warning',
			});
			return;
		}
		if (credentials.password.length < 1) {
			setAlert({
				show: true,
				message: 'Introduzca una contraseña válida.',
				severity: 'warning',
			});
			return;
		}
		const encrypt = new JSEncrypt();
		encrypt.setPublicKey(
			`-----BEGIN PUBLIC KEY-----\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuIGVY6DVBZ/X4rWIOC5B\nkwXupvlRDZibogBmdkoER0Z3WX8PtyWcpf09fcvjaBw6Xwcw73E6uQMWMbSYR/Q8\n/6lk7TjQ7bDBnJ5M2ZI3cXhVEth2sGPfdrTWwiDjmyTOCtUUrs7DkC1vwV/uWQNo\n/Ed2wTdY6VKk8Dnkg4yZwqfzwFzJ82dh8zh0l08UHP+35B5SqDkbi4x/xCf7Qgp4\ng7omgBLfxqsTAllWAs2Ra+1jn3xzr4gOdbNZpXuoCRfrcrE/EcXbTxaPqArXSzff\nVZqjR5ulv5o5dRPT4vu7f2RKAhheXfWjQ3fzmlrckBfQf2EC3aBUu4mZmnzMIYJt\nMQIDAQAB\n-----END PUBLIC KEY-----`
		);
		const base64_credentials = encrypt.encrypt(
			credentials.email + ':' + credentials.password
		) as string;

		try {
			setLoader(true);
			authAPI
				.post<
					{ credentials: string },
					{
						data: {
							member: IUser;
							email: string;
							role: string;
							sign_in_count: any;
						};
						headers: { authorization: string };
					}
				>('', {
					credentials: base64_credentials,
				})
				.then((res) => {
					if (res.headers.authorization) {
						const { data, headers } = res;
						dispatch(
							actions.setUser({
								...data.member,
								email: data.email,
								role: data.role,
								token: headers.authorization,
								sign_in_count: data.sign_in_count,
							})
						);
						setOpenLogin(!openLogin);
					} else {
						setOpenCred(true);
					}
				})
				.catch((error) => {
					if (error?.description)
						setAlert({
							show: true,
							message:
								error?.description || 'Usuario o contraseña incorrectos',
							severity: 'error',
						});
					else setOpenCred(true);
				})
				.finally(() => {
					setLoader(false);
				});
		} catch (error) {
			setOpenCred(true);
		} finally {
			setAlert({
				message: '',
				show: false,
				severity: 'success',
			});
		}
	};

	return (
		<>
			<NoCredential open={openCred} setOpen={setOpenCred} />
			<FullWidthLoader open={loader} />
			<Dialog
				sx={{ '& .MuiDialog-paper': { borderRadius: '22px', width: 550 } }}
				open={openLogin}
				onClose={() => setOpenLogin(false)}
				maxWidth={'sm'}
				scroll={'body'}
			>
				<div className={classes.container} ref={wrapperRef}>
					<img
						src={close}
						alt="cerrar"
						onClick={() => setOpenLogin(!openLogin)}
						className={classes.closeButton}
					/>
					<div className={classes.div}>
						<div className={classes.divText}>
							<p className={classes.title}>Bienvenido a Socio Infonavit </p>
						</div>
						<Link
							target="_blank"
							href="https://portalmx.infonavit.org.mx/wps/wcm/connect/2454799c-d968-4e58-91ee-39592875e295/API_SI.pdf?MOD=AJPERES&ContentCache=NONE&CACHE=NONE&CVID=ouqkq8H"
							style={{ textDecorationColor: '#8E8E8E' }}
						>
							<p className={classes.aviso}>
								Consulta nuestro Aviso de Privacidad
							</p>
						</Link>
						<p className={classes.subTitle}>
							Ingresa con tu Número de Seguridad Social (NSS) y 
							contraseña de Mi cuenta Infonavit
						</p>
						<div className={classes.containerInput}>
							<TextField
								id="email"
								label="NSS (11 dígitos)"
								variant="outlined"
								name="email"
								type="text"
								onChange={setData}
								className={classes.input}
								margin="normal"
								onKeyPress={handleKeypress}
								value={credentials.email}
								inputProps={{ maxLength: 11 }}
								// error={!emailError.valid}
								// helperText={!emailError.valid ? emailError.msg : ''}
							/>
							<Link
							target="_blank"
							href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS"
							style={{ textDecoration: 'none'  }}
							>
							<p className={classes.passwordLost}>
								Consulta tu Número de Seguridad Social
							</p>
						</Link>
							<TextField
								id="password"
								label="Contraseña"
								variant="outlined"
								name="password"
								type="password"
								className={classes.input}
								onChange={setData}
								margin="normal"
								value={credentials.password}
								onKeyPress={handleKeypress}
							/>
						</div>
						<Link
							target="_blank"
							href="https://micuenta.infonavit.org.mx/cambio-contrasenia"
							style={{ textDecoration: 'none' }}
						>
							<p className={classes.passwordLost}>Olvidé mi contraseña</p>
						</Link>

						{alert.show && (
							<Alert severity={alert.severity}>{alert.message}</Alert>
						)}
						<div className={classes.divButton}>
							<button onClick={onSubmit} className={classes.button}>
								Entrar
							</button>
							<p className={classes.text}>
							Si aún no estás registrado en Mi Cuenta Infonavit.
							</p>
							<Link
							target="_blank"
							href="https://micuenta.infonavit.org.mx/registro"
							style={{ textDecoration: 'none' }}
						>
							<p className={classes.register}>Registrate aquí</p>
						</Link>
						</div>
					</div>
				</div>
			</Dialog>
		</>
	);
};

export default Login;

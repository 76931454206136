import React from 'react';
import { CategoryLink, Title, Icon } from './styles';

interface ICategory {
  key?: string;
  className?: string;
  data: any;
};


const Category = ({ data, ...props }: ICategory) => {

  return (
    <CategoryLink href={`/resultados?categories=${data.id}`} {...props}>
      <Icon src={data?.icon_url} alt={data?.name} />
      <Title>{data?.name}</Title>
    </CategoryLink>
  );
};

export default Category;
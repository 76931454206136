import React from 'react';
import BuscarBenevits from '../../assets/img/buscar-benevits.png';

const WithoutBenevits = () => {
	return (
		<div className='col-12 my-6 d-flex flex-column align-items-center'>
			<img
        width={300}
				src={BuscarBenevits}
				alt="No se encontraron resultados"
        className='mb-4'
			/>
			<h4>
				<strong> No se encontraron resultados </strong>
			</h4>
			<p>Prueba con otra palabra o utiliza otros filtros</p>
		</div>
	);
};

export default WithoutBenevits;
import styled from "@emotion/styled";

export const StyledCheckbox = styled.div`
  input[type="checkbox"] {
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(221,5,40,.25);
    }

    &:checked {
      background-color: #DD0528;
      border-color: #DD0528;
    }
  }
`;

export const StyledLabel = styled.label`
  font-size: 14px;
  cursor: pointer;
`;
import React, { useState, useEffect } from 'react';
import { BannerContainer, Banner, Title, Description, Logo } from './styles';
import { getCategoryById, getBrandById } from '../../utils/searchFunctions';
import { brandsAPI } from '../../api/modules/brands';

const SearchBanner = ({ values, data, query, categories, brands }: any) => {
  const [category, setCategory] = useState<any>(null);
  const [brand, setBrand] = useState<any>(null);
  const [brandsData, setBrandsData] = useState<any>([]);
  const categoryId = values?.categories[0];
  const brandId = values?.brands[0];

  const _fetchBrands = async () => {
    const resBrands = await brandsAPI.brandsFilter();
    setBrandsData(resBrands?.data);
  }

  // Fetch brands data
  useEffect(() => {
    _fetchBrands();
  }, []);


  // Set category and brand
  useEffect(() => {
    if (categories.length > 0) {
      setCategory(getCategoryById(categoryId, categories));
    }

    if (values?.brands?.length > 0) {
      setBrand(getBrandById(values?.brands[0], brandsData));
    }
  }, [values, categories, brandsData]);

  // Show search banner
  if (query) return (
    <Banner>
      <Title>Resultados para "{query}" {data && (`(${data.length})`)}</Title>
    </Banner>
  );

  // Show category banner
  if (values?.categories?.length === 1 && values?.brands?.length === 0 && categoryId) {
    return (
      <Banner>
        <Title>{category?.label}</Title>
      </Banner>
    );
  }

  // Show brand banner
  if (values?.brands?.length === 1 && values?.categories?.length === 0 && brandId) {
    return (
      <BannerContainer>
        <Banner outline={true}>
          <Logo src={brand?.mini_logo_full_path} alt={brand?.name} />
          <Description>{brand?.description}</Description>
        </Banner>
      </BannerContainer>
    );
  }

  return null;
}

export default SearchBanner;